var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('b-row', [_c('b-col', [_c('b-card', {
    scopedSlots: _vm._u([{
      key: "header",
      fn: function fn() {
        return [_c('h3', {
          staticClass: "mr-3"
        }, [_vm._v(_vm._s(_vm.partner.name))]), _c('b-button', {
          attrs: {
            "variant": "success"
          },
          on: {
            "click": _vm.saveProfileBtnHandler
          }
        }, [_vm._v("Save Changes")])];
      },
      proxy: true
    }])
  }, [_c('b-card-body', [_c('b-card-text', [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "6",
      "sm": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Address",
      "label-for": "address"
    }
  }, [_c('b-form-textarea', {
    attrs: {
      "id": "address",
      "rows": "3"
    },
    model: {
      value: _vm.partner.address,
      callback: function callback($$v) {
        _vm.$set(_vm.partner, "address", $$v);
      },
      expression: "partner.address"
    }
  })], 1), _c('b-form-group', {
    attrs: {
      "label": "Company Reg. No.",
      "label-for": "companyRegNo"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "companyRegNo"
    },
    model: {
      value: _vm.partner.companyRegNo,
      callback: function callback($$v) {
        _vm.$set(_vm.partner, "companyRegNo", $$v);
      },
      expression: "partner.companyRegNo"
    }
  })], 1), _c('b-form-group', {
    attrs: {
      "label": "Access Email",
      "label-for": "email"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "email"
    },
    model: {
      value: _vm.partner.email,
      callback: function callback($$v) {
        _vm.$set(_vm.partner, "email", $$v);
      },
      expression: "partner.email"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6",
      "sm": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Access ID",
      "label-for": "accessId"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "accessId",
      "readonly": ""
    },
    model: {
      value: _vm.partner.accessId,
      callback: function callback($$v) {
        _vm.$set(_vm.partner, "accessId", $$v);
      },
      expression: "partner.accessId"
    }
  })], 1), _c('b-form-group', {
    attrs: {
      "label": "Access Secret",
      "label-for": "accessSecret"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "accessSecret",
      "readonly": ""
    },
    model: {
      value: _vm.partner.accessSecret,
      callback: function callback($$v) {
        _vm.$set(_vm.partner, "accessSecret", $$v);
      },
      expression: "partner.accessSecret"
    }
  })], 1), _c('b-button', {
    attrs: {
      "variant": "success"
    },
    on: {
      "click": _vm.regenerateBtnHandler
    }
  }, [_vm._v("Regenerate")])], 1)], 1)], 1)], 1)], 1), _c('b-card', {
    scopedSlots: _vm._u([{
      key: "header",
      fn: function fn() {
        return [_c('b-button', {
          staticClass: "ml-auto",
          attrs: {
            "variant": "success"
          },
          on: {
            "click": _vm.updateAccountBtnHandler
          }
        }, [_vm._v("Save Changes")])];
      },
      proxy: true
    }])
  }, [_c('b-card-body', [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "6",
      "sm": "12"
    }
  }, [_c('b-form-group', [_c('h3', [_vm._v("Currency Accounts")]), _c('small', [_vm._v("Add and edit currency accounts")]), _c('b-form', {
    attrs: {
      "inline": ""
    }
  }, [_c('b-form-select', {
    staticClass: "mr-1",
    attrs: {
      "options": _vm.currencies
    },
    model: {
      value: _vm.selectedCurrency,
      callback: function callback($$v) {
        _vm.selectedCurrency = $$v;
      },
      expression: "selectedCurrency"
    }
  }), _c('b-button', {
    staticClass: "mt-1",
    attrs: {
      "variant": "success"
    },
    on: {
      "click": _vm.addAccountBtnHandler
    }
  }, [_vm._v("Add")])], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6",
      "sm": "12"
    }
  }, _vm._l(_vm.accounts, function (account) {
    return _c('b-card', {
      key: account.id,
      staticClass: "border border-dark"
    }, [_c('b-card-body', [_c('h3', [_vm._v(_vm._s(account.currency) + " ACCOUNT")]), _c('h3', [_vm._v(_vm._s(account.name))]), _c('b-form-group', {
      attrs: {
        "label": "Rate to USD"
      }
    }, [_c('b-form-input', {
      attrs: {
        "type": "number",
        "size": "lg"
      },
      model: {
        value: account.rate,
        callback: function callback($$v) {
          _vm.$set(account, "rate", $$v);
        },
        expression: "account.rate"
      }
    })], 1)], 1)], 1);
  }), 1)], 1)], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }