import VueCompositionAPI from "@vue/composition-api";
import { ModalPlugin, ToastPlugin } from "bootstrap-vue";
import Vue from "vue";

import App from "./App.vue";
import router from "./router";
import store from "./store";

// Global Components
import "./global-components";

// 3rd party plugins
import "@/libs/acl";
import "@/libs/portal-vue";
import "@/libs/toastification";
import "@axios";
import VueMask from "v-mask";
//font awesome plugins
import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import "vue-good-table/dist/vue-good-table.css";
library.add(fas, far, fab);
Vue.component("font-awesome-icon", FontAwesomeIcon);

Vue.use(VueMask);

//vue-clipboard-2
import VueClipboard from "vue-clipboard2";
Vue.use(VueClipboard);

//dayjs
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
dayjs.extend(customParseFormat);
Object.defineProperties(Vue.prototype, {
  $date: {
    get() {
      return dayjs;
    },
  },
});

// BSV Plugin Registration
Vue.use(ToastPlugin);
Vue.use(ModalPlugin);

// Composition API
Vue.use(VueCompositionAPI);

// import core styles
require("@core/scss/core.scss");

// import assets styles
require("@/assets/scss/style.scss");

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
