<template>
    <div>
        <b-row>
            <b-col offset-md="10" offset-sm="0">
                <!-- Account Select -->
                <b-form-select v-model="selectedAccount" :options="accountOptions" class="mb-2" size="lg" />
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <vue-good-table mode="remote" @on-page-change="onPageChange" @on-sort-change="onSortChange" @on-column-filter="onColumnFilter" @on-per-page-change="onPerPageChange" :columns="header" :rows="columnData" @on-row-click="onRowClick" :total-rows="pageTotal" :per-page="serverParams.perPage" :isLoading.sync="isLoading" :pagination-options="{
                    enabled: true,
                }">
                    <template slot="table-row" slot-scope="props">
                        <span v-if="props.column.field === 'txStatus'">
                            <b-badge variant="success" v-if="props.row[props.column.field] === 'SUCCESS'">
                                {{ props.row[props.column.field] }}
                            </b-badge>
                            <b-badge variant="warning" v-else-if="props.row[props.column.field] === 'PENDING'">
                                {{ props.row[props.column.field] }}
                            </b-badge>
                            <b-badge variant="danger" v-else>
                                {{ props.row[props.column.field] }}
                            </b-badge>
                        </span>
                        <span v-else-if="props.column.field === 'psp'">
                            {{ props.row[props.column.field].id }}
                        </span>
                        <span v-else>
                            {{ props.row[props.column.field] }}
                        </span>

                    </template>
                    <template slot="pagination-bottom" slot-scope="props">
                        <div class="d-flex justify-content-between flex-wrap">
                            <div class="d-flex align-items-center mb-0 mt-1">
                                <span class="text-nowrap">
                                    Showing 1 to
                                </span>
                                <b-form-select v-model="serverParams.perPage" :options="[5, 10, 20, 50, 100]" class="mx-1" @input="(value) => props.perPageChanged({ currentPerPage: value })" />
                                <span class="text-nowrap"> of {{ props.total }} entries </span>
                            </div>

                            <b-pagination :value="1" :total-rows="pageTotal" :per-page="pageLength" first-number last-number align="right" prev-class="prev-item" next-class="next-item" class="mt-1 mb-0" @input="(value) => props.pageChanged({ currentPage: value })">
                                <template #prev-text>
                                    <feather-icon icon="ChevronLeftIcon" size="18" />
                                </template>
                                <template #next-text>
                                    <feather-icon icon="ChevronRightIcon" size="18" />
                                </template>
                            </b-pagination>
                        </div>
                    </template>
                </vue-good-table>
            </b-col>
        </b-row>

        <!-- Transaction Details Modal -->
        <b-modal id="customerDetails-modal" title="Transaction Details" size="xl" ok-title="Save" cancel-title="Cancel" centered f>
            <b-row>
                <b-col>
                    <b-card title="Transaction ID" sub-title="MerchantTxID">
                        <b-card-text>
                            {{ modal.merchantTxId }}
                        </b-card-text>
                    </b-card>
                </b-col>
                <b-col>
                    <b-card title="Transaction ID 2" sub-title="PartnerTxID">
                        <b-card-text>
                            {{ modal.partnerTxId }}
                        </b-card-text>
                    </b-card>
                </b-col>
                <b-col>
                    <b-card title="Submitted Amount" sub-title="Partner value in selected currency">
                        <b-card-text>
                            {{ modal.submitAmount }}
                        </b-card-text>
                    </b-card>
                </b-col>
                <b-col>
                    <b-card title="Transaction Status" sub-title="Status of the transaction">
                        <b-card-text>
                            {{ modal.txStatus }}
                        </b-card-text>
                    </b-card>
                </b-col>
                <b-col>
                    <b-card title="Transaction Type" sub-title="Type of the transaction">
                        <b-card-text>
                            {{ modal.type }}
                        </b-card-text>
                    </b-card>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <b-card>
                        <b-card-body>
                            <b-row>
                                <b-col>
                                    <b-row><b-col md="3"><span>Submit Amount:</span></b-col><b-col><span>{{ modal.submitAmount }}</span></b-col></b-row>
                                    <b-row><b-col md="3"><span>Partner Amount:</span></b-col><b-col><span>{{ modal.partnerAmount }}</span></b-col></b-row>
                                    <b-row><b-col md="3"><span>Merchant Fees:</span></b-col><b-col><span>{{ modal.merchantFee }}</span></b-col></b-row>
                                    <b-row><b-col md="3"><span>Routing ID:</span></b-col><b-col><span>{{ modal.psp?.id }}</span></b-col></b-row>
                                    <b-row><b-col md="3"><span>Submitted:</span></b-col><b-col><span>{{ modal.createdAt }}</span></b-col></b-row>
                                    <b-row><b-col md="3"><span>ProccessedAt:</span></b-col><b-col><span>{{ modal.processedAt }}</span></b-col></b-row>
                                    <b-row><b-col md="3"><span>Callback:</span></b-col><b-col><span>{{ modal.callback }}</span></b-col></b-row>
                                    <b-row><b-col md="3"><span>Account:</span></b-col><b-col><span>{{ modal.account?.name }}</span></b-col></b-row>
                                </b-col>
                                <b-col>
                                    <b-row><b-col md="3"><span>Email:</span></b-col><b-col><span>{{ modal.email }}</span></b-col></b-row>
                                    <b-row><b-col md="3"><span>Name:</span></b-col><b-col><span>{{ modal.name }}</span></b-col></b-row>
                                    <b-row><b-col md="3"><span>Country:</span></b-col><b-col><span>{{ modal.country }}</span></b-col></b-row>
                                    <b-row><b-col md="3"><span>State:</span></b-col><b-col><span>{{ modal.state }}</span></b-col></b-row>
                                    <b-row><b-col md="3"><span>Address:</span></b-col><b-col><span>{{ modal.address }}</span></b-col></b-row>
                                </b-col>
                            </b-row>
                        </b-card-body>
                    </b-card>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <b-card title="API Log">
                        <b-card-text>
                            {{ modal.response }}
                        </b-card-text>
                    </b-card>
                </b-col>
            </b-row>
        </b-modal>
    </div>
</template >

<script>
import { BCard, BCardText, BCol, BRow, BCardBody, BButton, BModal, BPagination, BFormSelect, BBadge } from 'bootstrap-vue';
import { getTransactions, getAccounts } from "@/services/AdminService";
import { VueGoodTable } from 'vue-good-table';

export default {
    name: "Transactions",
    components: {
        BCard,
        BCardText,
        BRow,
        BCol,
        BCardBody,
        BButton,
        BModal,
        VueGoodTable,
        BPagination,
        BFormSelect,
        BBadge
    },
    data() {
        return {
            isLoading: false,
            modal: {},
            accounts: [],
            selectedAccount: null,
            pageTotal: 0,
            pageLength: 10,
            columnData: [{}],
            serverParams: {
                columnFilters: {},
                sort: [
                    {
                        field: 'createdAt',
                        type: 'desc'
                    }
                ],
                page: 1,
                perPage: 10
            }
        }
    },
    async mounted() {
        const partnerId = this.$route.params.partnerId;
        this.accounts = await getAccounts(partnerId);
        this.selectedAccount = this.accounts[0].id;
        this.loadItems();
    },
    computed: {
        isMobile() {
            const currentBreakPoint = this.$store.getters["app/currentBreakPoint"];
            const isMobile = currentBreakPoint === "xs";
            return isMobile;
        },
        header() {
            return [
                {
                    label: "MerchantTxID",
                    field: "merchantTxId",
                    type: "string",
                    filterOptions: {
                        enabled: true
                    }
                },
                {
                    label: "PartnerTxID",
                    field: "partnerTxId",
                    type: "string",
                    filterOptions: {
                        enabled: true
                    }
                },
                {
                    label: "Submitted Amount",
                    field: "submitAmount",
                },
                {
                    label: "Partner Amount",
                    field: "partnerAmount",
                },
                {
                    label: "Merchant Fees",
                    field: "merchantFee",
                },
                {
                    label: "Status",
                    field: "txStatus",
                    filterOptions: {
                        enabled: true,
                        filterDropdownItems: [
                            { text: 'Pending', value: 'PENDING' },
                            { text: 'Success', value: 'SUCCESS' },
                            { text: 'Failed', value: 'FAILED' },
                        ]
                    },
                },
                {
                    label: "Trans. Type",
                    field: "type",
                    filterOptions: {
                        enabled: true,
                        filterDropdownItems: [
                            { text: 'Payin', value: 'Payin' },
                            { text: 'Payout', value: 'Payout' },
                        ]
                    },
                },
                {
                    label: "Routing ID",
                    field: "psp",
                },
                {
                    label: "Email",
                    field: "email",
                    filterOptions: {
                        enabled: true
                    }
                },
                {
                    label: "Date Submitted",
                    field: "createdAt",
                    type: "date",
                    dateInputFormat: "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'",
                    dateOutputFormat: "dd/MM/yyyy HH:mm:ss",

                },
                {
                    label: "Date Proc.",
                    field: "processedAt",
                    type: "date",
                    dateInputFormat: "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'",
                    dateOutputFormat: "dd/MM/yyyy HH:mm:ss",
                }
            ]
        },
        accountOptions() {
            return this.accounts.map(account => {
                return {
                    value: account.id,
                    text: account.name
                }
            });
        }
    },
    methods: {
        onRowClick(e) {
            this.modal = e.row;
            this.$bvModal.show("customerDetails-modal");
        },
        updateParams(newProps) {
            this.serverParams = Object.assign({}, this.serverParams, newProps);
        },
        async onPageChange(params) {
            this.updateParams({ page: params.currentPage });
            await this.loadItems();
        },
        async onPerPageChange(params) {
            this.updateParams({ perPage: params.currentPerPage });
            await this.loadItems();
        },
        async onSortChange(params) {
            this.updateParams({
                sort: params
            });
            await this.loadItems();
        },
        async onColumnFilter(params) {
            this.updateParams(params);
            await this.loadItems();
        },
        async loadItems() {
            this.isLoading = true;

            const skip = (this.serverParams.page - 1) * this.serverParams.perPage;
            const take = this.serverParams.perPage;
            const filter = this.serverParams.columnFilters;
            const sort = this.serverParams.sort;
            const { transactions, totalCount } = await getTransactions(this.selectedAccount, skip, take, sort, filter);
            this.columnData = transactions;
            this.pageTotal = totalCount;
            this.isLoading = false;
        },
    }
}
</script>