<template>
    <div>
        <b-row>
            <b-col>
                <b-card>
                    <template v-slot:header>
                        <h3 class="mr-3">{{ partner.name }}</h3>
                        <b-button variant="success" @click="saveProfileBtnHandler">Save Changes</b-button>
                    </template>
                    <b-card-body>
                        <b-card-text>
                            <b-row>
                                <b-col md="6" sm="12">
                                    <b-form-group label="Address" label-for="address">
                                        <b-form-textarea id="address" v-model="partner.address" rows="3" />
                                    </b-form-group>
                                    <b-form-group label="Company Reg. No." label-for="companyRegNo">
                                        <b-form-input id="companyRegNo" v-model="partner.companyRegNo" />
                                    </b-form-group>
                                    <b-form-group label="Access Email" label-for="email">
                                        <b-form-input id="email" v-model="partner.email" />
                                    </b-form-group>
                                </b-col>
                                <b-col md="6" sm="12">
                                    <b-form-group label="Access ID" label-for="accessId">
                                        <b-form-input id="accessId" v-model="partner.accessId" readonly />
                                    </b-form-group>
                                    <b-form-group label="Access Secret" label-for="accessSecret">
                                        <b-form-input id="accessSecret" v-model="partner.accessSecret" readonly />
                                    </b-form-group>
                                    <b-button variant="success" @click="regenerateBtnHandler">Regenerate</b-button>
                                </b-col>
                            </b-row>
                        </b-card-text>
                    </b-card-body>
                </b-card>
                <b-card>
                    <template v-slot:header>
                        <b-button class="ml-auto" variant="success" @click="updateAccountBtnHandler">Save Changes</b-button>
                    </template>
                    <b-card-body>
                        <b-row>
                            <b-col md="6" sm="12">
                                <b-form-group>
                                    <h3>Currency Accounts</h3>
                                    <small>Add and edit currency accounts</small>
                                    <b-form inline>
                                        <b-form-select class="mr-1" :options="currencies" v-model="selectedCurrency"></b-form-select>
                                        <b-button class="mt-1" variant="success" @click="addAccountBtnHandler">Add</b-button>
                                    </b-form>
                                </b-form-group>
                            </b-col>
                            <b-col md="6" sm="12">
                                <b-card class="border border-dark" v-for="account in accounts" v-bind:key="account.id">
                                    <b-card-body>
                                        <h3>{{ account.currency }} ACCOUNT</h3>
                                        <h3>{{ account.name }}</h3>
                                        <b-form-group label="Rate to USD">
                                            <b-form-input v-model="account.rate" type="number" size="lg" />
                                        </b-form-group>
                                    </b-card-body>
                                </b-card>
                            </b-col>
                        </b-row>
                    </b-card-body>
                </b-card>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import { addAccount, getAccounts, getProfile, regenerate, updateAccounts, updateProfile } from "@/services/AdminService";
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import { BButton, BCard, BCardBody, BCardText, BCardTitle, BCol, BForm, BFormCheckbox, BFormGroup, BFormInput, BFormSelect, BFormTextarea, BRow } from 'bootstrap-vue';
export default {
    name: "Profile",
    components: {
        BCard,
        BCardText,
        BRow, BCol, BCardBody, BButton, BFormCheckbox, BFormInput, BFormSelect, BFormGroup, BButton, BFormTextarea, BCardTitle, BForm
    },
    data() {
        return {
            partnerId: this.$route.params.partnerId,
            partner: {
                id: '',
                name: '',
                email: '',
                image: '',
                password: ''
            },
            selectedCurrency: '',
            currencies: ['USD', 'MYR', 'SGD'],
            accounts: []
        }
    },
    async mounted() {
        this.partner = await getProfile(this.partnerId);
        this.accounts = await getAccounts(this.partnerId);
    },
    methods: {
        async saveProfileBtnHandler() {
            try {
                await updateProfile(this.partnerId, this.partner);
                this.partner = await getProfile(this.partnerId);
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Update Success!',
                        icon: 'CheckIcon',
                        variant: 'success',
                    },
                });
            }
            catch (err) {
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Update Failed!',
                        icon: 'AlertTriangleIcon',
                        variant: 'danger',
                    },
                });
            }
        },
        async regenerateBtnHandler() {
            //are you sure dialog

            const res = await regenerate(this.partnerId);
            this.partner.accessId = res.accessId;
            this.partner.accessSecret = res.accessSecret;
        },
        async updateAccountBtnHandler() {
            try {
                await updateAccounts(this.accounts);
                this.accounts = await getAccounts(this.partnerId);
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Update Success!',
                        icon: 'CheckIcon',
                        variant: 'success',
                    },
                });
            }
            catch (err) {
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Update Failed!',
                        icon: 'AlertTriangleIcon',
                        variant: 'danger',
                    },
                });
            }
        },
        async addAccountBtnHandler() {
            try {
                await addAccount(this.partnerId, this.selectedCurrency);
                this.accounts = await getAccounts(this.partnerId, this.selectedCurrency);
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Add Success!',
                        icon: 'CheckIcon',
                        variant: 'success',
                    },
                });
            }
            catch (err) {
                console.log(err);
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Add Failed!',
                        icon: 'AlertTriangleIcon',
                        variant: 'danger',
                    },
                });
            }
        }
    }
}
</script>