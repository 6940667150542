<template>
  <div>
    <b-card title="April's Top Performer" sub-title="This month's highest value">
      <div class="d-flex align-items-center">
        <img :src="topPerformer.image" class="img-fluid" width="200" height="auto" alt="Card image cap" />
        <div class="ml-3">
          <h1 class="mb-0">{{ topPerformer.name }}</h1>
        </div>
        <div class="ml-auto">
          <h1 class="mb-0">{{ topPerformer.total }} USD</h1>
        </div>
      </div>
    </b-card>

    <!-- loop -->

    <b-card sub-title="Current Statistics" v-for="partner in top3" v-bind:key="partner.id">
      <div class="d-flex align-items-center">
        <img :src="partner.image" class="img-fluid" width="200" height="auto" alt="Card image cap" />
        <div class="ml-3">
          <h2 class="mb-0">{{ partner.name }}</h2>
        </div>
      </div>
      <!-- Fund balance card , transactions card , total settlements card-->
      <b-row>
        <b-col mt-4>
          <b-card title="Fund Balance">
            <b-card-text>
              <h2 class="mb-0">{{ partner.fundBalance }}</h2>
            </b-card-text>
          </b-card>
        </b-col>
        <b-col>
          <b-card title="Transactions">
            <b-card-text>
              <h2 class="mb-0">{{ partner.transactions }}</h2>
            </b-card-text>
          </b-card>
        </b-col>
        <b-col>
          <b-card title="Total Settlements">
            <b-card-text>
              <h2 class="mb-0">{{ partner.totalSettlements }}</h2>
            </b-card-text>
          </b-card>
        </b-col>
      </b-row>
    </b-card>

    <!-- Top Merchant Earnings -->
    <b-card title="Total Merchant Earning" sub-title="Payversa total profit">
      <b-card-body>
        <div class="d-flex align-items-center">
          <div class="ml-auto">
            <h1 class="mb-0">{{ totalMerchantEarnings }}</h1>
          </div>
        </div>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import { getDashboard } from '@/services/AdminService';
import { BCard, BCardText, BCol, BRow, BCardBody } from 'bootstrap-vue';

export default {
  components: {
    BCard,
    BCardText,
    BRow, BCol,
    BCardBody
  },
  data() {
    return {
      topPerformer: {},
      top3: [{}],
      totalMerchantEarnings: 0.00
    }
  },
  async mounted() {
    const dashboard = await getDashboard();
    this.topPerformer = dashboard.topPerformer;
    this.top3 = dashboard.top3;
    this.totalMerchantEarnings = dashboard.totalMerchantEarnings;
  }
}
</script>

<style></style>
