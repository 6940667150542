var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('b-row', [_c('b-col', [_c('b-card', [_c('vue-good-table', {
    attrs: {
      "columns": _vm.header,
      "rows": _vm.columnData,
      "total-rows": _vm.pageTotal,
      "per-page": _vm.pageLength,
      "pagination-options": {
        enabled: false
      },
      "sort-options": {
        enabled: false
      }
    }
  })], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }