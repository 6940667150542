<template>
    <div>
        <b-row>
            <b-col>
                <b-card title="Create New Settlement" sub-title="Create new settlement to wallet or bank account">
                    <b-card-body>
                        <b-row>
                            <b-col>
                                <b-form-group label="Partner" label-for="partner">
                                    <b-form-select id="partner" size="lg" v-model="form.partner" :options="partners" @change="getAccountHandler"></b-form-select>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col>
                                <b-card>
                                    <b-card-text></b-card-text>
                                </b-card>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col>
                                <b-form-group label="Amount" label-for="amount">
                                    <b-form-input id="amount" size="lg" v-model="form.amount" type="number" placeholder="Enter amount"></b-form-input>
                                </b-form-group>
                            </b-col>
                            <b-col>
                                <b-form-group label="Account" label-for="account">
                                    <b-form-select id="account" size="lg" v-model="form.account" :options="accounts"></b-form-select>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col>
                                <b-form-group label="Destination" label-for="destination">
                                    <b-form-input value="Wallet - USDT" size="lg" readonly></b-form-input>
                                </b-form-group>
                            </b-col>
                            <b-col>
                                <b-form-group label="Wallet Network">
                                    <b-form-select v-model="form.network" size="lg" :options="networks"></b-form-select>
                                </b-form-group>
                            </b-col>
                            <b-col>
                                <b-form-group label="Address" label-for="address">
                                    <b-form-input id="address" size="lg" v-model="form.address" type="text" placeholder="Enter address"></b-form-input>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col>
                                <b-form-group label="Settlement Receipt">
                                    <b-form-file size="lg" accept="" v-model="form.receipt" placeholder="Choose a file or drop it here..."></b-form-file>
                                </b-form-group>
                            </b-col>
                            <b-col>
                                <b-form-group label="Status" label-for="status">
                                    <b-form-select id="status" size="lg" v-model="form.status" :options="status"></b-form-select>
                                </b-form-group>
                            </b-col>
                            <b-col>
                                <b-form-group label="Rate" label-for="rate">
                                    <b-form-input id="rate" size="lg" v-model="form.rate"></b-form-input>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-button variant="primary" size="lg" @click="onSubmitBtnHandler">Create</b-button>
                    </b-card-body>
                </b-card>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import { createSettlement, getAccounts, getPartners } from "@/services/AdminService";
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import { BButton, BCard, BCardBody, BCardHeader, BCardText, BCol, BFormCheckbox, BFormFile, BFormGroup, BFormInput, BFormSelect, BPagination, BRow } from 'bootstrap-vue';

export default {
    name: "CreateSettlement",
    components: {
        BCard,
        BCardText,
        BRow, BCol, BCardBody, BButton, BFormCheckbox, BFormInput, BFormSelect, BFormGroup, BButton, BPagination, BCardHeader, BFormFile
    },
    data() {
        return {
            partners: [],
            accounts: [],
            form: {
                partner: null,
                account: null,
                network: null,
                amount: null,
                address: null,
                status: null,
                receipt: null,
                rate: null,
            },
            status: [
                {
                    value: null,
                    text: "Select Status"
                },
                {
                    value: "PENDING",
                    text: "Pending",
                },
                {
                    value: "SUCCESS",
                    text: "Success",
                },
                {
                    value: "REJECTED",
                    text: "Rejected",
                },
            ],
            networks: [
                {
                    value: "ERC20",
                    text: "ERC20",
                },
                {
                    value: "TRC20",
                    text: "TRC20",
                }
            ]
        }
    },
    async mounted() {
        const partners = await getPartners();
        this.partners = partners.map((partner) => {
            return {
                value: partner.id,
                text: partner.name,
            }
        });
    },
    methods: {
        async getAccountHandler() {
            const accounts = await getAccounts(this.form.partner);
            this.accounts = accounts.map((account) => {
                return {
                    value: account.id,
                    text: account.name,
                    currency: account.currency,
                    balance: account.balance,
                }
            });
        },
        async onSubmitBtnHandler() {
            try {
                await createSettlement(this.form);
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Save Success!',
                        icon: 'CheckIcon',
                        variant: 'success',
                    },
                });
                this.$router.push('/settlements');
            }
            catch (e) {
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Save Failed!',
                        icon: 'AlertTriangleIcon',
                        variant: 'danger',
                        //get res error response body
                        text: e.response.data.message
                    },
                });
            }
        }
    }
}
</script>