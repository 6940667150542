<template>
    <div></div>
</template>

<script>
import useJwt from '@/auth/jwt/useJwt'

export default {
    name: "Logout",
    async mounted() {
        await useJwt.logout();
        this.$router.push('/login');
    }
}
</script>