var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('b-row', [_c('b-col', [_c('b-card', {
    attrs: {
      "title": "Add New Gateway Instance",
      "sub-title": "Add new option for gateway"
    }
  }, [_c('b-card-body', [_c('b-row', [_c('b-col', [_c('b-form-group', {
    attrs: {
      "label": "Name"
    }
  }, [_c('b-form-input', {
    model: {
      value: _vm.psp.name,
      callback: function callback($$v) {
        _vm.$set(_vm.psp, "name", $$v);
      },
      expression: "psp.name"
    }
  })], 1), _c('b-form-group', {
    attrs: {
      "label": "Gateway Type"
    }
  }, [_c('b-form-select', {
    attrs: {
      "options": _vm.methodOptions
    },
    model: {
      value: _vm.psp.method,
      callback: function callback($$v) {
        _vm.$set(_vm.psp, "method", $$v);
      },
      expression: "psp.method"
    }
  })], 1), _c('b-form-group', {
    attrs: {
      "label": "Handler / Provider"
    }
  }, [_c('b-form-select', {
    attrs: {
      "options": _vm.providerOptions
    },
    model: {
      value: _vm.psp.provider,
      callback: function callback($$v) {
        _vm.$set(_vm.psp, "provider", $$v);
      },
      expression: "psp.provider"
    }
  })], 1)], 1)], 1), _c('b-row', [_c('b-col', [_c('b-form-group', {
    attrs: {
      "label": "Access ID"
    }
  }, [_c('b-form-input', {
    model: {
      value: _vm.psp.accessId,
      callback: function callback($$v) {
        _vm.$set(_vm.psp, "accessId", $$v);
      },
      expression: "psp.accessId"
    }
  })], 1), _c('b-form-group', {
    attrs: {
      "label": "Access Secret"
    }
  }, [_c('b-form-input', {
    model: {
      value: _vm.psp.accessSecret,
      callback: function callback($$v) {
        _vm.$set(_vm.psp, "accessSecret", $$v);
      },
      expression: "psp.accessSecret"
    }
  })], 1)], 1)], 1), _c('b-row', [_c('b-col', [_c('b-button', {
    attrs: {
      "variant": "primary",
      "block": "",
      "size": "lg"
    },
    on: {
      "click": _vm.saveBtnHandler
    }
  }, [_vm._v("Save")])], 1)], 1)], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }