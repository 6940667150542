var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('b-row', [_c('b-col', _vm._l(Object.keys(_vm.config), function (key) {
    return _c('div', [_c('b-card', {
      scopedSlots: _vm._u([{
        key: "header",
        fn: function fn() {
          return [key === 'INSTANT_TRANSFER' ? _c('h3', {
            staticClass: "mr-3"
          }, [_vm._v("INSTANT TRANSFER")]) : key === 'CREDIT_CARD' ? _c('h3', {
            staticClass: "mr-3"
          }, [_vm._v("CREDIT CARD")]) : key === 'THIRD_PARTY' ? _c('h3', {
            staticClass: "mr-3"
          }, [_vm._v("THIRD PARTY")]) : key === 'PAYOUT' ? _c('h3', {
            staticClass: "mr-3"
          }, [_vm._v("PAYOUT")]) : _vm._e(), _c('b-form-checkbox', {
            staticClass: "mr-auto",
            attrs: {
              "switch": ""
            },
            model: {
              value: _vm.enabled[key],
              callback: function callback($$v) {
                _vm.$set(_vm.enabled, key, $$v);
              },
              expression: "enabled[key]"
            }
          }), _c('b-button', {
            attrs: {
              "variant": "success"
            },
            on: {
              "click": _vm.saveBtnHandler
            }
          }, [_vm._v("Save Changes")])];
        },
        proxy: true
      }], null, true)
    }), _vm._l(_vm.config[key], function (config) {
      return _c('b-card', {
        key: config.id
      }, [_c('b-card-body', [_c('b-row', [_c('b-col', {
        attrs: {
          "md": "4",
          "sm": "12"
        }
      }, [_c('h1', {
        staticClass: "mr-3"
      }, [_vm._v(_vm._s(config.psp.name))]), _c('b-row', [_c('b-col', {
        attrs: {
          "md": "4",
          "sm": "12"
        }
      }, [_c('span', [_vm._v("Force Currency:")]), _c('span', [_vm._v(_vm._s(config.currency))])]), _c('b-col', {
        attrs: {
          "md": "4",
          "sm": "12"
        }
      }, [_c('span', [_vm._v("Priority:")]), _c('b-form-select', {
        attrs: {
          "options": [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
        },
        model: {
          value: config.priority,
          callback: function callback($$v) {
            _vm.$set(config, "priority", $$v);
          },
          expression: "config.priority"
        }
      })], 1)], 1), _c('b-row', [_c('b-col', {
        attrs: {
          "md": "4",
          "sm": "12"
        }
      }, [_c('span', [_vm._v(" Toggle Active:")]), _c('b-form-checkbox', {
        attrs: {
          "switch": ""
        },
        model: {
          value: config.isActive,
          callback: function callback($$v) {
            _vm.$set(config, "isActive", $$v);
          },
          expression: "config.isActive"
        }
      })], 1), _c('b-col', {
        attrs: {
          "md": "4",
          "sm": "12"
        }
      }, [_c('span', [_vm._v("Account")]), _c('b-form-input', {
        model: {
          value: config.account.name,
          callback: function callback($$v) {
            _vm.$set(config.account, "name", $$v);
          },
          expression: "config.account.name"
        }
      })], 1)], 1)], 1), _c('b-col', {
        attrs: {
          "md": "4",
          "sm": "12"
        }
      }, [_c('h4', [_vm._v("Rulesets")]), _c('b-row', [_c('b-col', {
        attrs: {
          "md": "4",
          "sm": "12"
        }
      }, [_c('span', [_vm._v("Country:")]), _c('b-form-input', {
        model: {
          value: config.country,
          callback: function callback($$v) {
            _vm.$set(config, "country", $$v);
          },
          expression: "config.country"
        }
      }), _c('span', [_vm._v("Min Amount")]), _c('b-form-input', {
        model: {
          value: config.minAmount,
          callback: function callback($$v) {
            _vm.$set(config, "minAmount", $$v);
          },
          expression: "config.minAmount"
        }
      }), _c('span', [_vm._v("Max Amount")]), _c('b-form-input', {
        model: {
          value: config.maxAmount,
          callback: function callback($$v) {
            _vm.$set(config, "maxAmount", $$v);
          },
          expression: "config.maxAmount"
        }
      })], 1)], 1)], 1)], 1)], 1)], 1);
    })], 2);
  }), 0)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }