var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('b-row', [_c('b-col', [_c('b-card', [_c('b-card-header', [_c('b-button', {
    attrs: {
      "variant": "primary",
      "size": "lg"
    },
    on: {
      "click": function click($event) {
        return _vm.$router.push('/settlements/create');
      }
    }
  }, [_vm._v("Create Settlement")])], 1), _c('b-card-body', [_c('vue-good-table', {
    attrs: {
      "columns": _vm.header,
      "rows": _vm.columnData,
      "total-rows": _vm.pageTotal,
      "per-page": _vm.pageLength,
      "pagination-options": {
        enabled: true
      },
      "sort-options": {
        enabled: true
      }
    },
    on: {
      "on-cell-click": _vm.cellClickHandler
    },
    scopedSlots: _vm._u([{
      key: "table-row",
      fn: function fn(props) {
        return [props.column.field === 'settlementId' ? _c('span', [_c('a', {
          attrs: {
            "href": "javascript:void(0)"
          }
        }, [_vm._v(" " + _vm._s(props.row.settlementId) + " ")])]) : props.column.field === 'attachment' ? _c('span', [_c('a', {
          attrs: {
            "href": "javascript:void(0)"
          }
        }, [_vm._v(" screenshot.png ")])]) : props.column.field === 'status' ? _c('span', [props.row[props.column.field] === 'SUCCESS' ? _c('b-badge', {
          attrs: {
            "variant": "success"
          }
        }, [_vm._v(" " + _vm._s(props.row[props.column.field]) + " ")]) : props.row[props.column.field] === 'PENDING' ? _c('b-badge', {
          attrs: {
            "variant": "warning"
          }
        }, [_vm._v(" " + _vm._s(props.row[props.column.field]) + " ")]) : _c('b-badge', {
          attrs: {
            "variant": "danger"
          }
        }, [_vm._v(" " + _vm._s(props.row[props.column.field]) + " ")])], 1) : _c('span', [_vm._v(" " + _vm._s(props.row[props.column.field]) + " ")])];
      }
    }, {
      key: "pagination-bottom",
      fn: function fn(props) {
        return [_c('div', {
          staticClass: "d-flex justify-content-between flex-wrap"
        }, [_c('div', {
          staticClass: "d-flex align-items-center mb-0 mt-1"
        }, [_c('span', {
          staticClass: "text-nowrap"
        }, [_vm._v(" Showing 1 to ")]), _c('b-form-select', {
          staticClass: "mx-1",
          attrs: {
            "options": ['5', '10', '20', '50', '100']
          },
          on: {
            "input": function input(value) {
              return props.perPageChanged({
                currentPerPage: value
              });
            }
          },
          model: {
            value: _vm.pageLength,
            callback: function callback($$v) {
              _vm.pageLength = $$v;
            },
            expression: "pageLength"
          }
        }), _c('span', {
          staticClass: "text-nowrap"
        }, [_vm._v(" of " + _vm._s(props.total) + " entries ")])], 1), _c('b-pagination', {
          staticClass: "mt-1 mb-0",
          attrs: {
            "value": 1,
            "total-rows": _vm.pageTotal,
            "per-page": _vm.pageLength,
            "first-number": "",
            "last-number": "",
            "align": "right",
            "prev-class": "prev-item",
            "next-class": "next-item"
          },
          on: {
            "input": function input(value) {
              return props.pageChanged({
                currentPage: value
              });
            }
          },
          scopedSlots: _vm._u([{
            key: "prev-text",
            fn: function fn() {
              return [_c('feather-icon', {
                attrs: {
                  "icon": "ChevronLeftIcon",
                  "size": "18"
                }
              })];
            },
            proxy: true
          }, {
            key: "next-text",
            fn: function fn() {
              return [_c('feather-icon', {
                attrs: {
                  "icon": "ChevronRightIcon",
                  "size": "18"
                }
              })];
            },
            proxy: true
          }], null, true)
        })], 1)];
      }
    }])
  })], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }