<template>
    <div>
        <b-row>
            <b-col>
                <div v-for="key in Object.keys(config)">
                    <b-card>
                        <template v-slot:header>
                            <h3 class="mr-3" v-if="key === 'INSTANT_TRANSFER'">INSTANT TRANSFER</h3>
                            <h3 class="mr-3" v-else-if="key === 'CREDIT_CARD'">CREDIT CARD</h3>
                            <h3 class="mr-3" v-else-if="key === 'THIRD_PARTY'">THIRD PARTY</h3>
                            <h3 class="mr-3" v-else-if="key === 'PAYOUT'">PAYOUT</h3>
                            <b-form-checkbox switch v-model="enabled[key]" class="mr-auto"></b-form-checkbox>
                            <b-button variant="success" @click="saveBtnHandler">Save Changes</b-button>
                        </template>
                    </b-card>
                    <b-card v-for="config in config[key]" v-bind:key="config.id">
                        <b-card-body>
                            <b-row>
                                <b-col md="4" sm="12">
                                    <h1 class="mr-3">{{ config.psp.name }}</h1>
                                    <b-row>
                                        <b-col md="4" sm="12">
                                            <span>Force Currency:</span>
                                            <span>{{ config.currency }}</span>
                                        </b-col>
                                        <b-col md="4" sm="12">
                                            <span>Priority:</span>
                                            <b-form-select v-model="config.priority" :options="[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]" />
                                        </b-col>
                                    </b-row>
                                    <b-row>
                                        <b-col md="4" sm="12">

                                            <span> Toggle Active:</span>
                                            <b-form-checkbox switch v-model="config.isActive" />

                                        </b-col>
                                        <b-col md="4" sm="12">
                                            <span>Account</span>
                                            <b-form-input v-model="config.account.name" />
                                        </b-col>
                                    </b-row>
                                </b-col>
                                <b-col md="4" sm="12">
                                    <h4>Rulesets</h4>
                                    <b-row>
                                        <b-col md="4" sm="12">
                                            <span>Country:</span>
                                            <b-form-input v-model="config.country" />
                                            <span>Min Amount</span>
                                            <b-form-input v-model="config.minAmount" />
                                            <span>Max Amount</span>
                                            <b-form-input v-model="config.maxAmount" />
                                        </b-col>
                                    </b-row>
                                </b-col>
                            </b-row>
                        </b-card-body>
                    </b-card>
                </div>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import { getPspConfigs, updatePspConfig } from "@/services/AdminService";
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import { BButton, BCard, BCardBody, BCardText, BCol, BFormCheckbox, BFormGroup, BFormInput, BFormSelect, BRow } from 'bootstrap-vue';

export default {
    name: "Routes",
    components: {
        BCard,
        BCardText,
        BRow, BCol, BCardBody, BButton, BFormCheckbox, BFormInput, BFormSelect, BFormGroup, BButton
    },
    data() {
        return {
            config: [],
            enabled: [],
            accounts: [],
            partnerId: this.$route.params.partnerId
        }
    },
    async mounted() {
        const res = await getPspConfigs(this.partnerId);
        this.config = res.configs;
        this.enabled = res.enabledPsp;
    },
    methods: {
        async saveBtnHandler() {
            try {
                const res = await updatePspConfig(this.partnerId, this.config, this.enabled);
                //this.config = res.configs;
                //this.enabled = res.enabledPsp;

                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Save Success!',
                        icon: 'CheckIcon',
                        variant: 'success',
                    },
                });
            }
            catch (err) {
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Save Failed!',
                        icon: 'AlertTriangleIcon',
                        variant: 'danger',
                    },
                });
            }
        }
    }
}
</script>