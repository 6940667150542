<template>
    <div>
        <b-row>
            <b-col offset-md="10" offset-sm="0">
                <!-- Account Select -->
                <b-form-select v-model="selectedPspId" :options="psps" @change="getChipDashboard" class="mb-2" size="lg">
                    <template #first>
                        <b-form-select-option :value="null" disabled>Select PSP</b-form-select-option>
                    </template>
                </b-form-select>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <b-card>
                    <b-card-header>
                        <b-card-title>Accounts</b-card-title>
                    </b-card-header>
                    <b-card-body>
                        <div>
                            <vue-good-table :columns="[
                                {
                                    label: 'ID',
                                    field: 'id',
                                    type: 'number'
                                },
                                {
                                    label: 'Currency',
                                    field: 'currency',
                                    type: 'string'
                                },
                                {
                                    label: 'Curr. Balance',
                                    field: 'current_balance',
                                    type: 'string'
                                },
                                {
                                    label: 'Status',
                                    field: 'status',
                                    type: 'string'
                                }
                            ]" :rows="accounts" :total-rows="accounts.length" :per-page="10" :pagination-options="{
    enabled: false,
}" :sort-options="{
    enabled: false
}">
                            </vue-good-table>
                        </div>
                    </b-card-body>
                </b-card>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <b-card>
                    <b-card-header>
                        <b-card-title>Bank Accounts</b-card-title>
                        <b-button variant="primary" size="md" v-b-modal.add-bank-account-modal>Add</b-button>
                    </b-card-header>
                    <b-card-body>
                        <div>
                            <vue-good-table :columns="[
                                {
                                    label: 'ID',
                                    field: 'id',
                                    type: 'number'
                                },
                                {
                                    label: 'Bank Code',
                                    field: 'bank_code',
                                    type: 'string'
                                },
                                {
                                    label: 'Acc. No.',
                                    field: 'account_number',
                                    type: 'string'
                                },
                                {
                                    label: 'Status',
                                    field: 'status',
                                    type: 'string'
                                }
                            ]" :rows="bankAccounts" :total-rows="bankAccounts.length" :per-page="10" :pagination-options="{
    enabled: true,
}" :sort-options="{
    enabled: false
}">
                            </vue-good-table>
                        </div>
                    </b-card-body>
                </b-card>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <b-card>
                    <b-card-header>
                        <b-card-title>Send Limits</b-card-title>
                        <b-button variant="primary" size="md" v-b-modal.add-send-limit-modal>Add</b-button>
                    </b-card-header>
                    <b-card-body>
                        <div>
                            <vue-good-table :columns="[
                                {
                                    label: 'ID',
                                    field: 'id',
                                    type: 'number'
                                },
                                {
                                    label: 'Amount',
                                    field: 'amount',
                                    type: 'number'
                                },
                                {
                                    label: 'Currency',
                                    field: 'currency',
                                    type: 'string'
                                },
                                {
                                    label: 'Status',
                                    field: 'status',
                                    type: 'string'
                                }
                            ]" :rows="sendLimits" :total-rows="sendLimits.length" :per-page="10" :pagination-options="{
    enabled: true,
}" :sort-options="{
    enabled: false
}">
                            </vue-good-table>
                        </div>
                    </b-card-body>
                </b-card>
            </b-col>
        </b-row>
        <b-modal id="add-send-limit-modal" title="Add Send Limit" @ok="addSendLimit">
            <b-form>
                <b-form-group label="Amount">
                    <b-form-input type="number" v-model="sendLimitForm.amount" />
                </b-form-group>
            </b-form>
        </b-modal>
        <b-modal id="add-bank-account-modal" title="Add Bank Account" @ok="addBankAccount">
            <b-form>
                <b-form-group label="Bank Code">
                    <b-form-select v-model="bankAccountForm.bankCode" :options="['PHBMMYKL', 'AIBBMYKL', 'BPMBMYKL', 'MFBBMYKL', 'ALSRMYK1', 'RJHIMYKL', 'ARBKMYKL', 'AISLMYKL', 'BIMBMYKL', 'BKRMMYKL', 'BMMBMYKL', 'BSNAMYK1', 'CIBBMYKL', 'CTBBMYKL', 'CITIMYKL', 'HLBBMYKL', 'HLIBMYKL', 'HBMBMYKL', 'HMABMYKL', 'KFHOMYKL', 'MBBEMYKL', 'MBISMYKL', 'OCBCMYKL', 'OABBMYKL', 'PBBEMYKL', 'PIBEMYK1', 'RHBBMYKL', 'RHBAMYKL', 'SCBLMYKX', 'UOVBMYKL', 'AFBQMYKL']">
                        <template #first>
                            <b-form-select-option :value="null" disabled>Select Bank Code</b-form-select-option>
                        </template>
                    </b-form-select>
                </b-form-group>
                <b-form-group label="Account Number">
                    <b-form-input type="number" v-model="bankAccountForm.accountNumber" />
                </b-form-group>
                <b-form-group label="Account Name">
                    <b-form-input type="text" v-model="bankAccountForm.accountName" />
                </b-form-group>
            </b-form>
        </b-modal>
    </div>
</template >

<script>
import { getChipDashboard, getChipPsps, addSendLimit, addBankAccount } from "@/services/ChipService";
import { BButton, BCard, BCardBody, BCardHeader, BCardFooter, BCardText, BCardTitle, BCol, BFormSelect, BFormSelectOption, BModal, BPagination, BRow, BForm, BFormGroup, BFormInput } from 'bootstrap-vue';
import { VueGoodTable } from 'vue-good-table';

export default {
    name: "Chip",
    components: {
        BCard,
        BCardText,
        BRow,
        BForm,
        BCol,
        BCardBody,
        BCardTitle,
        BButton,
        BFormGroup,
        BFormInput,
        BFormSelectOption,
        BCardFooter,
        BCardHeader,
        BModal,
        VueGoodTable,
        BPagination,
        BFormSelect,
    },
    data() {
        return {
            selectedPspId: null,
            psps: [],
            accounts: [],
            bankAccounts: [],
            sendLimits: [],
            bankAccountForm: {
                bankCode: "",
                accountNumber: "",
                accountName: "",
            },
            sendLimitForm: {
                amount: 0,
            }
        }
    },
    async mounted() {
        this.psps = (await getChipPsps()).map(psp => {
            return {
                text: psp.name,
                value: psp.id,
            }
        });

    },
    computed: {
        isMobile() {
            const currentBreakPoint = this.$store.getters["app/currentBreakPoint"];
            const isMobile = currentBreakPoint === "xs";
            return isMobile;
        },
    },
    methods: {
        async getChipDashboard() {
            const res = await getChipDashboard(this.selectedPspId);
            this.accounts = res.accounts;
            this.bankAccounts = res.bankAccounts;
            this.sendLimits = res.sendLimits;
        },
        async addBankAccount() {
            if (this.selectedPspId === null) return;
            await addBankAccount(this.selectedPspId, this.bankAccountForm);

            this.bankAccountForm = {
                bankCode: "",
                accountNumber: "",
                accountName: "",
            }
        },
        async addSendLimit() {
            if (this.selectedPspId === null) return;
            await addSendLimit(this.selectedPspId, this.sendLimitForm);

            this.addSendLimitForm = {
                amount: 0,
            }
        },
    },
}
</script>