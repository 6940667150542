var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', {
    staticClass: "clearfix mb-0"
  }, [_c('span', {
    staticClass: "float-md-right d-none d-md-block"
  }, [_vm._v("Payversa Dashboard v1.0 "), _c('font-awesome-icon', {
    staticClass: "icons",
    attrs: {
      "icon": "heart-broken",
      "size": "4x"
    }
  })], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }