var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', _vm._l(_vm.partners, function (partner) {
    return _c('b-card', {
      key: partner.id,
      scopedSlots: _vm._u([{
        key: "header",
        fn: function fn() {
          return [_c('div', {
            staticClass: "d-flex align-items-center"
          }, [_c('img', {
            staticClass: "img-fluid",
            attrs: {
              "src": partner.image,
              "width": "50",
              "height": "auto",
              "alt": "Card image cap"
            }
          }), _c('div', {
            staticClass: "ml-3"
          }, [_c('h3', {
            staticClass: "mb-0"
          }, [_vm._v(_vm._s(partner.name))])])])];
        },
        proxy: true
      }], null, true)
    }, [_c('b-card-body', [_c('b-row', [_c('b-col', [_c('b-card', {
      attrs: {
        "title": "Fund Balance",
        "sub-title": "Total Balance in USD"
      }
    }, [_c('b-card-text', [_c('h2', {
      staticClass: "mb-0"
    }, [_vm._v(_vm._s(partner.fundBalance) + " USD")])])], 1)], 1), _c('b-col', [_c('b-card', {
      attrs: {
        "title": "Total Fund In",
        "sub-title": "Total Success Fund-in in USD"
      }
    }, [_c('b-card-text', [_c('h2', {
      staticClass: "mb-0"
    }, [_vm._v(_vm._s(partner.fundInBalance) + " USD")])])], 1)], 1), _c('b-col', [_c('b-card', {
      attrs: {
        "title": "Gateway Fee",
        "sub-title": "Total Gateway Fees in USD"
      }
    }, [_c('b-card-text', [_c('h2', {
      staticClass: "mb-0"
    }, [_vm._v(_vm._s(partner.gatewayFee) + " USD")])])], 1)], 1), _c('b-col', [_c('b-card', {
      attrs: {
        "title": "Total Merchant Fees",
        "sub-title": "Total Merchant Fees in USD"
      }
    }, [_c('b-card-text', [_c('h2', {
      staticClass: "mb-0"
    }, [_vm._v(_vm._s(partner.totalMerchantFee) + " USD")])])], 1)], 1), _c('b-col', [_c('b-card', {
      attrs: {
        "title": "Total Settlements",
        "sub-title": "Total Settlements in USD"
      }
    }, [_c('b-card-text', [_c('h2', {
      staticClass: "mb-0"
    }, [_vm._v(_vm._s(partner.totalSettlement) + " USD")])])], 1)], 1)], 1), _c('b-row', {
      staticClass: "d-flex align-items-center"
    }, [_c('b-button', {
      staticClass: "mr-1",
      attrs: {
        "variant": "primary",
        "size": "lg"
      },
      on: {
        "click": function click($event) {
          return _vm.transactionBtnHandler(partner.id);
        }
      }
    }, [_vm._v("Transactions")]), _c('b-button', {
      staticClass: "mr-1",
      attrs: {
        "variant": "primary",
        "size": "lg"
      },
      on: {
        "click": function click($event) {
          return _vm.routingBtnHandler(partner.id);
        }
      }
    }, [_vm._v("Routing & Config")]), _c('b-button', {
      staticClass: "mr-1",
      attrs: {
        "variant": "primary",
        "size": "lg"
      },
      on: {
        "click": function click($event) {
          return _vm.profileBtnHandler(partner.id);
        }
      }
    }, [_vm._v("Profile")]), _c('b-button', {
      attrs: {
        "variant": "primary",
        "size": "lg"
      },
      on: {
        "click": function click($event) {
          return _vm.balanceBtnHandler(partner.id);
        }
      }
    }, [_vm._v("Balances")])], 1)], 1)], 1);
  }), 1);
}
var staticRenderFns = []

export { render, staticRenderFns }