var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('b-row', [_c('b-col', [_c('b-card', {
    attrs: {
      "title": "Create New Settlement",
      "sub-title": "Create new settlement to wallet or bank account"
    }
  }, [_c('b-card-body', [_c('b-row', [_c('b-col', [_c('b-form-group', {
    attrs: {
      "label": "Partner",
      "label-for": "partner"
    }
  }, [_c('b-form-select', {
    attrs: {
      "id": "partner",
      "size": "lg",
      "options": _vm.partners
    },
    on: {
      "change": _vm.getAccountHandler
    },
    model: {
      value: _vm.form.partner,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "partner", $$v);
      },
      expression: "form.partner"
    }
  })], 1)], 1)], 1), _c('b-row', [_c('b-col', [_c('b-card', [_c('b-card-text')], 1)], 1)], 1), _c('b-row', [_c('b-col', [_c('b-form-group', {
    attrs: {
      "label": "Amount",
      "label-for": "amount"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "amount",
      "size": "lg",
      "type": "number",
      "placeholder": "Enter amount"
    },
    model: {
      value: _vm.form.amount,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "amount", $$v);
      },
      expression: "form.amount"
    }
  })], 1)], 1), _c('b-col', [_c('b-form-group', {
    attrs: {
      "label": "Account",
      "label-for": "account"
    }
  }, [_c('b-form-select', {
    attrs: {
      "id": "account",
      "size": "lg",
      "options": _vm.accounts
    },
    model: {
      value: _vm.form.account,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "account", $$v);
      },
      expression: "form.account"
    }
  })], 1)], 1)], 1), _c('b-row', [_c('b-col', [_c('b-form-group', {
    attrs: {
      "label": "Destination",
      "label-for": "destination"
    }
  }, [_c('b-form-input', {
    attrs: {
      "value": "Wallet - USDT",
      "size": "lg",
      "readonly": ""
    }
  })], 1)], 1), _c('b-col', [_c('b-form-group', {
    attrs: {
      "label": "Wallet Network"
    }
  }, [_c('b-form-select', {
    attrs: {
      "size": "lg",
      "options": _vm.networks
    },
    model: {
      value: _vm.form.network,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "network", $$v);
      },
      expression: "form.network"
    }
  })], 1)], 1), _c('b-col', [_c('b-form-group', {
    attrs: {
      "label": "Address",
      "label-for": "address"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "address",
      "size": "lg",
      "type": "text",
      "placeholder": "Enter address"
    },
    model: {
      value: _vm.form.address,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "address", $$v);
      },
      expression: "form.address"
    }
  })], 1)], 1)], 1), _c('b-row', [_c('b-col', [_c('b-form-group', {
    attrs: {
      "label": "Settlement Receipt"
    }
  }, [_c('b-form-file', {
    attrs: {
      "size": "lg",
      "accept": "",
      "placeholder": "Choose a file or drop it here..."
    },
    model: {
      value: _vm.form.receipt,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "receipt", $$v);
      },
      expression: "form.receipt"
    }
  })], 1)], 1), _c('b-col', [_c('b-form-group', {
    attrs: {
      "label": "Status",
      "label-for": "status"
    }
  }, [_c('b-form-select', {
    attrs: {
      "id": "status",
      "size": "lg",
      "options": _vm.status
    },
    model: {
      value: _vm.form.status,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "status", $$v);
      },
      expression: "form.status"
    }
  })], 1)], 1), _c('b-col', [_c('b-form-group', {
    attrs: {
      "label": "Rate",
      "label-for": "rate"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "rate",
      "size": "lg"
    },
    model: {
      value: _vm.form.rate,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "rate", $$v);
      },
      expression: "form.rate"
    }
  })], 1)], 1)], 1), _c('b-button', {
    attrs: {
      "variant": "primary",
      "size": "lg"
    },
    on: {
      "click": _vm.onSubmitBtnHandler
    }
  }, [_vm._v("Create")])], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }