var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('b-row', [_c('b-col', {
    attrs: {
      "offset-md": "10",
      "offset-sm": "0"
    }
  }, [_c('b-form-select', {
    staticClass: "mb-2",
    attrs: {
      "options": _vm.psps,
      "size": "lg"
    },
    on: {
      "change": _vm.getChipDashboard
    },
    scopedSlots: _vm._u([{
      key: "first",
      fn: function fn() {
        return [_c('b-form-select-option', {
          attrs: {
            "value": null,
            "disabled": ""
          }
        }, [_vm._v("Select PSP")])];
      },
      proxy: true
    }]),
    model: {
      value: _vm.selectedPspId,
      callback: function callback($$v) {
        _vm.selectedPspId = $$v;
      },
      expression: "selectedPspId"
    }
  })], 1)], 1), _c('b-row', [_c('b-col', [_c('b-card', [_c('b-card-header', [_c('b-card-title', [_vm._v("Accounts")])], 1), _c('b-card-body', [_c('div', [_c('vue-good-table', {
    attrs: {
      "columns": [{
        label: 'ID',
        field: 'id',
        type: 'number'
      }, {
        label: 'Currency',
        field: 'currency',
        type: 'string'
      }, {
        label: 'Curr. Balance',
        field: 'current_balance',
        type: 'string'
      }, {
        label: 'Status',
        field: 'status',
        type: 'string'
      }],
      "rows": _vm.accounts,
      "total-rows": _vm.accounts.length,
      "per-page": 10,
      "pagination-options": {
        enabled: false
      },
      "sort-options": {
        enabled: false
      }
    }
  })], 1)])], 1)], 1)], 1), _c('b-row', [_c('b-col', [_c('b-card', [_c('b-card-header', [_c('b-card-title', [_vm._v("Bank Accounts")]), _c('b-button', {
    directives: [{
      name: "b-modal",
      rawName: "v-b-modal.add-bank-account-modal",
      modifiers: {
        "add-bank-account-modal": true
      }
    }],
    attrs: {
      "variant": "primary",
      "size": "md"
    }
  }, [_vm._v("Add")])], 1), _c('b-card-body', [_c('div', [_c('vue-good-table', {
    attrs: {
      "columns": [{
        label: 'ID',
        field: 'id',
        type: 'number'
      }, {
        label: 'Bank Code',
        field: 'bank_code',
        type: 'string'
      }, {
        label: 'Acc. No.',
        field: 'account_number',
        type: 'string'
      }, {
        label: 'Status',
        field: 'status',
        type: 'string'
      }],
      "rows": _vm.bankAccounts,
      "total-rows": _vm.bankAccounts.length,
      "per-page": 10,
      "pagination-options": {
        enabled: true
      },
      "sort-options": {
        enabled: false
      }
    }
  })], 1)])], 1)], 1)], 1), _c('b-row', [_c('b-col', [_c('b-card', [_c('b-card-header', [_c('b-card-title', [_vm._v("Send Limits")]), _c('b-button', {
    directives: [{
      name: "b-modal",
      rawName: "v-b-modal.add-send-limit-modal",
      modifiers: {
        "add-send-limit-modal": true
      }
    }],
    attrs: {
      "variant": "primary",
      "size": "md"
    }
  }, [_vm._v("Add")])], 1), _c('b-card-body', [_c('div', [_c('vue-good-table', {
    attrs: {
      "columns": [{
        label: 'ID',
        field: 'id',
        type: 'number'
      }, {
        label: 'Amount',
        field: 'amount',
        type: 'number'
      }, {
        label: 'Currency',
        field: 'currency',
        type: 'string'
      }, {
        label: 'Status',
        field: 'status',
        type: 'string'
      }],
      "rows": _vm.sendLimits,
      "total-rows": _vm.sendLimits.length,
      "per-page": 10,
      "pagination-options": {
        enabled: true
      },
      "sort-options": {
        enabled: false
      }
    }
  })], 1)])], 1)], 1)], 1), _c('b-modal', {
    attrs: {
      "id": "add-send-limit-modal",
      "title": "Add Send Limit"
    },
    on: {
      "ok": _vm.addSendLimit
    }
  }, [_c('b-form', [_c('b-form-group', {
    attrs: {
      "label": "Amount"
    }
  }, [_c('b-form-input', {
    attrs: {
      "type": "number"
    },
    model: {
      value: _vm.sendLimitForm.amount,
      callback: function callback($$v) {
        _vm.$set(_vm.sendLimitForm, "amount", $$v);
      },
      expression: "sendLimitForm.amount"
    }
  })], 1)], 1)], 1), _c('b-modal', {
    attrs: {
      "id": "add-bank-account-modal",
      "title": "Add Bank Account"
    },
    on: {
      "ok": _vm.addBankAccount
    }
  }, [_c('b-form', [_c('b-form-group', {
    attrs: {
      "label": "Bank Code"
    }
  }, [_c('b-form-select', {
    attrs: {
      "options": ['PHBMMYKL', 'AIBBMYKL', 'BPMBMYKL', 'MFBBMYKL', 'ALSRMYK1', 'RJHIMYKL', 'ARBKMYKL', 'AISLMYKL', 'BIMBMYKL', 'BKRMMYKL', 'BMMBMYKL', 'BSNAMYK1', 'CIBBMYKL', 'CTBBMYKL', 'CITIMYKL', 'HLBBMYKL', 'HLIBMYKL', 'HBMBMYKL', 'HMABMYKL', 'KFHOMYKL', 'MBBEMYKL', 'MBISMYKL', 'OCBCMYKL', 'OABBMYKL', 'PBBEMYKL', 'PIBEMYK1', 'RHBBMYKL', 'RHBAMYKL', 'SCBLMYKX', 'UOVBMYKL', 'AFBQMYKL']
    },
    scopedSlots: _vm._u([{
      key: "first",
      fn: function fn() {
        return [_c('b-form-select-option', {
          attrs: {
            "value": null,
            "disabled": ""
          }
        }, [_vm._v("Select Bank Code")])];
      },
      proxy: true
    }]),
    model: {
      value: _vm.bankAccountForm.bankCode,
      callback: function callback($$v) {
        _vm.$set(_vm.bankAccountForm, "bankCode", $$v);
      },
      expression: "bankAccountForm.bankCode"
    }
  })], 1), _c('b-form-group', {
    attrs: {
      "label": "Account Number"
    }
  }, [_c('b-form-input', {
    attrs: {
      "type": "number"
    },
    model: {
      value: _vm.bankAccountForm.accountNumber,
      callback: function callback($$v) {
        _vm.$set(_vm.bankAccountForm, "accountNumber", $$v);
      },
      expression: "bankAccountForm.accountNumber"
    }
  })], 1), _c('b-form-group', {
    attrs: {
      "label": "Account Name"
    }
  }, [_c('b-form-input', {
    attrs: {
      "type": "text"
    },
    model: {
      value: _vm.bankAccountForm.accountName,
      callback: function callback($$v) {
        _vm.$set(_vm.bankAccountForm, "accountName", $$v);
      },
      expression: "bankAccountForm.accountName"
    }
  })], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }