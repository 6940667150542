import { $themeConfig } from "@themeConfig";

export default {
  namespaced: true,
  state: {
    isVerticalMenuCollapsed: $themeConfig.layout.menu.isCollapsed,
    appName: "HOI",
    appLogoImage: "/images/logo-hoi.png",
  },
  getters: {},
  mutations: {
    UPDATE_VERTICAL_MENU_COLLAPSED(state, val) {
      state.isVerticalMenuCollapsed = val;
    },
    UPDATE_APP_NAME(state, val) {
      state.appName = val;
    },
    UPDATE_APP_LOGO_IMAGE(state, val) {
      state.appLogoImage = val;
    },
  },
  actions: {},
};
