<template>
    <div>
        <b-row>
            <b-col>
                <b-card class="text-center" header-bg-variant="primary" header-text-variant="white" :header="`${settlement.account.name} Fund Balance`">
                    <b-card-text class="mt-4">
                        <h2>{{ settlement.account.balance }} {{ settlement.account.currency }}</h2>
                        <h2>{{ settlement.account.usdBalance }} USD</h2>
                    </b-card-text>
                </b-card>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <b-card title="Process Settlement" sub-title="Process settlement to wallet or bank account">
                    <b-card-body>
                        <b-row>
                            <b-col>
                                <b-form-group :label="`Amount (${settlement.account.currency})`" label-for="amount">
                                    <b-form-input id="amount" size="lg" v-model="settlement.amount" type="number" readonly></b-form-input>
                                </b-form-group>
                            </b-col>
                            <b-col>
                                <b-form-group label="Account" label-for="account">
                                    <b-form-input id="account" size="lg" v-model="settlement.account.name" readonly></b-form-input>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col>
                                <b-form-group label="Destination" label-for="destination">
                                    <b-form-input value="Wallet - USDT" size="lg" readonly></b-form-input>
                                </b-form-group>
                            </b-col>
                            <b-col>
                                <b-form-group label="Wallet Network">
                                    <b-form-input v-model="settlement.walletNetwork" size="lg" readonly></b-form-input>
                                </b-form-group>
                            </b-col>
                            <b-col>
                                <b-form-group label="Address" label-for="address">
                                    <b-form-input id="address" size="lg" v-model="settlement.walletAddress" readonly></b-form-input>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col>
                                <b-form-group label="Settlement Receipt">
                                    <b-form-file v-if="settlement.attachment === null" size="lg" accept="application/pdf, image/png, image/jpeg" v-model="settlement.attachment" placeholder="Choose a file or drop it here..."></b-form-file>
                                    <a :href="settlement.attachment" v-else target="_blank"><b-button variant="primary" size="sm">View Receipt</b-button></a>
                                </b-form-group>
                            </b-col>
                            <b-col>
                                <b-form-group label="Status" label-for="status">
                                    <b-form-select id="status" size="lg" v-model="settlement.status" :options="status"></b-form-select>
                                </b-form-group>
                            </b-col>
                            <b-col>
                                <b-form-group :label="`Rate (${settlement.account.currency} to USD)`" label-for="rate">
                                    <b-form-input id="rate" size="lg" v-model="settlement.rate"></b-form-input>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-button variant="primary" size="lg" class="mt-4" block @click="onSubmitBtnHandler">Done</b-button>
                    </b-card-body>
                </b-card>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import { getSettlement, updateSettlement } from "@/services/AdminService";
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import { BButton, BCard, BCardBody, BCardHeader, BCardText, BCol, BFormCheckbox, BFormFile, BFormGroup, BFormInput, BFormSelect, BPagination, BRow } from 'bootstrap-vue';

export default {
    name: "Settlement",
    components: {
        BCard,
        BCardText,
        BRow, BCol, BCardBody, BButton, BFormCheckbox, BFormInput, BFormSelect, BFormGroup, BButton, BPagination, BCardHeader, BFormFile
    },
    data() {
        return {
            settlementId: this.$route.params.settlementId,
            settlement: {
                account: {},
                amount: 0,
                rate: 0,
                approvedAt: null,
                attachment: null,
                createdAt: null,
                destinationAccount: null,
                id: null,
                settlementId: null,
                status: null,
                updatedAt: null,
                walletAddress: null,
                walletNetwork: null,
            },
            status: [
                { value: "PENDING", text: "Pending" },
                { value: "SUCCESS", text: "Success" },
                { value: "REJECTED", text: "Rejected" },
            ]
        }
    },
    async mounted() {
        this.settlement = await getSettlement(this.settlementId);
    },
    methods: {
        async onSubmitBtnHandler() {
            try {
                await updateSettlement(this.settlement);
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Update Success!',
                        icon: 'CheckIcon',
                        variant: 'success',
                    },
                });
                this.$router.push("/settlements");
            }
            catch (err) {
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Update Failed!',
                        icon: 'AlertTriangleIcon',
                        variant: 'danger',
                        text: err.response.data.message
                    },
                });
            }
        }
    }
}
</script>