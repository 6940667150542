<template>
    <div>
        <b-row>
            <b-col>
                <b-card>
                    <vue-good-table :columns="header" :rows="columnData" :total-rows="pageTotal" :per-page="pageLength" :pagination-options="{
                        enabled: false,
                    }" :sort-options="{
    enabled: false
}">
                    </vue-good-table>
                </b-card>
            </b-col>
        </b-row>
    </div>
</template >

<script>
import { BCard, BCardText, BCol, BRow, BCardBody, BButton, BModal, BPagination, BFormSelect } from 'bootstrap-vue';
import { getBalances } from "@/services/AdminService";
import { VueGoodTable } from 'vue-good-table';

export default {
    name: "Transactions",
    components: {
        BCard,
        BCardText,
        BRow,
        BCol,
        BCardBody,
        BButton,
        BModal,
        VueGoodTable,
        BPagination,
        BFormSelect,
    },
    data() {
        return {
            partnerId: this.$route.params.partnerId,
            pageTotal: 0,
            pageLength: 10,
            columnData: [{}],
            modal: {},
            accounts: [],
            selectedAccount: null,
        }
    },
    async mounted() {
        const res = await getBalances(this.partnerId);
        this.columnData = res.accounts;
        this.pageTotal = this.columnData.length;

        //add total row at the bottom of the table
        this.columnData.push({
            name: "TOTAL",
            currency: "",
            balance: res.total.balance,
            usdBalance: res.total.usdBalance
        });

        //last column as header
        this.columnData.forEach((item, index) => {
            if (index === this.columnData.length - 1) {
                item.isHeader = true;
            }
        });
    },
    computed: {
        isMobile() {
            const currentBreakPoint = this.$store.getters["app/currentBreakPoint"];
            const isMobile = currentBreakPoint === "xs";
            return isMobile;
        },
        header() {
            return [
                {
                    label: "Account",
                    field: "name",
                    type: "string",
                },
                {
                    label: "Currency",
                    field: "currency",
                    type: "string",
                },
                {
                    label: "Amount",
                    field: "balance"
                },
                {
                    label: "USD Amount",
                    field: "usdBalance"
                }
            ]
        },
    },
    methods: {
        pageChanged(toPage) {

        },
    }
}
</script>