<template>
    <div>
        <b-row class="mb-2">
            <b-col>
                <b-button to="/psp/create" variant="primary" block size="lg">+ Add New Gateway Instance</b-button>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <div v-for="key in Object.keys(psps)">
                    <b-card>
                        <template v-slot:header>
                            <h3 class="mr-3" v-if="key === 'INSTANT_TRANSFER'">Instant Transfer Type Options</h3>
                            <h3 class="mr-3" v-else-if="key === 'CREDIT_CARD'">Credit Card Type Options</h3>
                            <h3 class="mr-3" v-else-if="key === 'THIRD_PARTY'">Third Party Type Options</h3>
                            <b-button variant="success" @click="saveBtnHandler">Save Changes</b-button>
                        </template>
                    </b-card>

                    <b-card v-for="psp in psps[key]" v-bind:key="psp.id">
                        <b-card-body>
                            <b-row>
                                <b-col>
                                    <b-form-group label="Name">
                                        <b-form-input size="lg" v-model="psp.name"></b-form-input>
                                    </b-form-group>
                                    <b-row>
                                        <b-col>
                                            <h6 class="mt-2">Integration Credentials</h6>
                                            <b-form-group label="Access ID">
                                                <b-form-input v-model="psp.accessId" />
                                            </b-form-group>
                                            <b-form-group label="Access Secret">
                                                <b-form-input v-model="psp.accessSecret" />
                                            </b-form-group>
                                        </b-col>
                                    </b-row>
                                </b-col>
                            </b-row>
                        </b-card-body>
                    </b-card>
                </div>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import { getPsps, updatePsps } from "@/services/AdminService";
import { BButton, BCard, BCardBody, BCardHeader, BCardText, BCol, BFormCheckbox, BFormGroup, BFormInput, BFormSelect, BPagination, BRow, BBadge } from 'bootstrap-vue';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';

export default {
    name: "Psp",
    components: {
        BCard,
        BCardText, BBadge,
        BRow, BCol, BCardBody, BButton, BFormCheckbox, BFormInput, BFormSelect, BFormGroup, BButton, BPagination, BCardHeader,
    },
    data() {
        return {
            psps: [],
        }
    },
    async mounted() {
        this.psps = await getPsps();
    },
    methods: {
        async saveBtnHandler() {
            try {
                await updatePsps(this.psps);

                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Save Success!',
                        icon: 'CheckIcon',
                        variant: 'success',
                    },
                });
            }
            catch (err) {
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Save Failed!',
                        icon: 'AlertTriangleIcon',
                        variant: 'danger',
                    },
                });
            }
        }
    }
}
</script>