var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('b-row', [_c('b-col', [_c('b-card', [_c('b-card-body', [_c('b-row', [_c('b-col', [_c('b-form-group', {
    attrs: {
      "label": "Company Name"
    }
  }, [_c('b-form-input', {
    model: {
      value: _vm.partner.name,
      callback: function callback($$v) {
        _vm.$set(_vm.partner, "name", $$v);
      },
      expression: "partner.name"
    }
  })], 1), _c('b-form-group', {
    attrs: {
      "label": "Company Address"
    }
  }, [_c('b-form-input', {
    model: {
      value: _vm.partner.address,
      callback: function callback($$v) {
        _vm.$set(_vm.partner, "address", $$v);
      },
      expression: "partner.address"
    }
  })], 1), _c('b-form-group', {
    attrs: {
      "label": "Company Reg. No"
    }
  }, [_c('b-form-input', {
    model: {
      value: _vm.partner.companyRegNo,
      callback: function callback($$v) {
        _vm.$set(_vm.partner, "companyRegNo", $$v);
      },
      expression: "partner.companyRegNo"
    }
  })], 1), _c('b-form-group', {
    attrs: {
      "label": "Access Email"
    }
  }, [_c('b-form-input', {
    model: {
      value: _vm.partner.email,
      callback: function callback($$v) {
        _vm.$set(_vm.partner, "email", $$v);
      },
      expression: "partner.email"
    }
  })], 1), _c('b-form-group', {
    attrs: {
      "label": "Password"
    }
  }, [_c('b-form-input', {
    model: {
      value: _vm.partner.password,
      callback: function callback($$v) {
        _vm.$set(_vm.partner, "password", $$v);
      },
      expression: "partner.password"
    }
  })], 1), _c('b-form-group', {
    attrs: {
      "label": "Company Image"
    }
  }, [_c('b-form-file', {
    attrs: {
      "accept": "image/png, image/jpeg"
    },
    model: {
      value: _vm.partner.image,
      callback: function callback($$v) {
        _vm.$set(_vm.partner, "image", $$v);
      },
      expression: "partner.image"
    }
  })], 1), _c('b-form-group', {
    attrs: {
      "label": "Currency"
    }
  }, [_c('b-form-select', {
    model: {
      value: _vm.partner.currency,
      callback: function callback($$v) {
        _vm.$set(_vm.partner, "currency", $$v);
      },
      expression: "partner.currency"
    }
  }, [_c('option', {
    attrs: {
      "value": "MYR"
    }
  }, [_vm._v("MYR")]), _c('option', {
    attrs: {
      "value": "USD"
    }
  }, [_vm._v("USD")])])], 1), _c('b-form-group', {
    attrs: {
      "label": "Callback URL"
    }
  }, [_c('b-form-input', {
    model: {
      value: _vm.partner.callbackURL,
      callback: function callback($$v) {
        _vm.$set(_vm.partner, "callbackURL", $$v);
      },
      expression: "partner.callbackURL"
    }
  })], 1), _c('b-form-group', {
    attrs: {
      "label": "Redirect URL"
    }
  }, [_c('b-form-input', {
    model: {
      value: _vm.partner.redirectURL,
      callback: function callback($$v) {
        _vm.$set(_vm.partner, "redirectURL", $$v);
      },
      expression: "partner.redirectURL"
    }
  })], 1), _c('b-form-group', {
    attrs: {
      "label": "Partner Fee (%)"
    }
  }, [_c('b-form-input', {
    model: {
      value: _vm.partner.partnerFee,
      callback: function callback($$v) {
        _vm.$set(_vm.partner, "partnerFee", $$v);
      },
      expression: "partner.partnerFee"
    }
  })], 1)], 1)], 1), _c('b-row', [_c('b-col', [_c('b-button', {
    attrs: {
      "variant": "primary",
      "size": "lg",
      "block": ""
    },
    on: {
      "click": _vm.saveBtnHandler
    }
  }, [_vm._v("Save")])], 1)], 1)], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }