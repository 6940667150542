<template>
    <div>
        <b-row>
            <b-col>
                <b-card>
                    <b-card-body>
                        <b-row>
                            <b-col>
                                <b-form-group label="Company Name">
                                    <b-form-input v-model="partner.name" />
                                </b-form-group>
                                <b-form-group label="Company Address">
                                    <b-form-input v-model="partner.address" />
                                </b-form-group>
                                <b-form-group label="Company Reg. No">
                                    <b-form-input v-model="partner.companyRegNo" />
                                </b-form-group>
                                <b-form-group label="Access Email">
                                    <b-form-input v-model="partner.email" />
                                </b-form-group>
                                <b-form-group label="Password">
                                    <b-form-input v-model="partner.password" />
                                </b-form-group>
                                <b-form-group label="Company Image">
                                    <b-form-file accept="image/png, image/jpeg" v-model="partner.image" />
                                </b-form-group>
                                <b-form-group label="Currency">
                                    <b-form-select v-model="partner.currency">
                                        <option value="MYR">MYR</option>
                                        <option value="USD">USD</option>
                                    </b-form-select>
                                </b-form-group>
                                <b-form-group label="Callback URL">
                                    <b-form-input v-model="partner.callbackURL" />
                                </b-form-group>
                                <b-form-group label="Redirect URL">
                                    <b-form-input v-model="partner.redirectURL" />
                                </b-form-group>
                                <b-form-group label="Partner Fee (%)">
                                    <b-form-input v-model="partner.partnerFee" />
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col>
                                <b-button @click="saveBtnHandler" variant="primary" size="lg" block>Save</b-button>
                            </b-col>
                        </b-row>
                    </b-card-body>
                </b-card>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import { createPartner } from "@/services/AdminService";
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import { BButton, BCard, BCardBody, BCardText, BCardTitle, BCol, BForm, BFormCheckbox, BFormGroup, BFormInput, BFormSelect, BFormTextarea, BRow, BFormFile } from 'bootstrap-vue';

export default {
    name: "Onboarding",
    components: {
        BCard,
        BCardText,
        BRow, BCol, BCardBody, BButton, BFormCheckbox, BFormInput, BFormSelect, BFormGroup, BButton, BFormTextarea, BCardTitle, BForm, BFormFile
    },
    data() {
        return {
            partner: {
                name: '',
                address: '',
                companyRegNo: '',
                email: '',
                password: '',
                image: null,
                currency: '',
                callbackURL: '',
                redirectURL: '',
                partnerFee: 0.00,
            }
        }
    },
    methods: {
        async saveBtnHandler() {
            try {
                await createPartner(this.partner);
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Update Success!',
                        icon: 'CheckIcon',
                        variant: 'success',
                    },
                });
            }
            catch (err) {
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Update Failed!',
                        icon: 'AlertTriangleIcon',
                        variant: 'danger',
                    },
                });
            }
        },
    }
}
</script>