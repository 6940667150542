export default [
  {
    title: "Dashboard",
    route: "dashboard",
    icon: "HomeIcon",
    resource: "Admin",
  },
  {
    title: "Partners",
    route: "partners",
    icon: "UsersIcon",
    resource: "Admin",
  },
  {
    title: "Onboarding",
    route: "onboarding",
    icon: "UserPlusIcon",
    resource: "Admin",
  },
  {
    title: "Payment Systems",
    route: "psp",
    icon: "CreditCardIcon",
    resource: "Admin",
  },
  {
    title: "Settlements",
    route: "settlements",
    icon: "DollarSignIcon",
    resource: "Admin",
  },
  {
    title: "Chip",
    route: "chip",
    icon: "GlobeIcon",
    resource: "Admin",
  },
  {
    title: "Bank Codes",
    route: "bankCodes",
    icon: "SlidersIcon",
    resource: "Admin",
  },
];
