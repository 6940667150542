<template>
    <div>
        <b-row>
            <b-col>
                <b-card title="Add New Gateway Instance" sub-title="Add new option for gateway">
                    <b-card-body>
                        <b-row>
                            <b-col>
                                <b-form-group label="Name">
                                    <b-form-input v-model="psp.name" />
                                </b-form-group>
                                <b-form-group label="Gateway Type">
                                    <b-form-select v-model="psp.method" :options="methodOptions"></b-form-select>
                                </b-form-group>
                                <b-form-group label="Handler / Provider">
                                    <b-form-select v-model="psp.provider" :options="providerOptions"></b-form-select>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col>
                                <b-form-group label="Access ID">
                                    <b-form-input v-model="psp.accessId" />
                                </b-form-group>
                                <b-form-group label="Access Secret">
                                    <b-form-input v-model="psp.accessSecret" />
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col>
                                <b-button variant="primary" @click="saveBtnHandler" block size="lg">Save</b-button>
                            </b-col>
                        </b-row>
                    </b-card-body>
                </b-card>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import { createPsp } from "@/services/AdminService";
import { BButton, BCard, BCardBody, BCardHeader, BCardText, BCol, BFormCheckbox, BFormGroup, BFormInput, BFormSelect, BPagination, BRow, BBadge } from 'bootstrap-vue';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';

export default {
    name: "CreatePsp",
    components: {
        BCard,
        BCardText, BBadge,
        BRow, BCol, BCardBody, BButton, BFormCheckbox, BFormInput, BFormSelect, BFormGroup, BButton, BPagination, BCardHeader,
    },
    data() {
        return {
            psp: {
                name: "",
                method: "",
                provider: "",
                accessId: "",
                accessSecret: "",
            },
            methodOptions: [
                {
                    text: "Instant Transfer",
                    value: "INSTANT_TRANSFER"
                },
                {
                    text: "Credit Card",
                    value: "CREDIT_CARD"
                },
                {
                    text: "Third Party",
                    value: "THIRD_PARTY"
                }
            ],
            providerOptions: [
                {
                    text: "Redipay",
                    value: "REDIPAY"
                },
                {
                    text: "SenangPay",
                    value: "SENANGPAY"
                },
                {
                    text: "WalaoPay",
                    value: "WALAOPAY"
                },
                {
                    text: "Chip",
                    value: "CHIP"
                }
            ]
        }
    },
    methods: {
        async saveBtnHandler() {
            try {
                await createPsp(this.psp);

                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Save Success!',
                        icon: 'CheckIcon',
                        variant: 'success',
                    },
                });
            }
            catch (e) {
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Save Failed!',
                        icon: 'AlertTriangleIcon',
                        variant: 'danger',
                    },
                });
            }
        }
    }
}
</script>