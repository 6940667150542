import { isUserLoggedIn } from "@/auth/utils";
import { canNavigate } from "@/libs/acl/routeProtection";
import Vue from "vue";
import VueRouter from "vue-router";
Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: "/",
      name: "dashboard",
      component: () => import("@/views/Dashboard.vue"),
      meta: {
        pageTitle: "Dashboard",
        breadcrumb: [
          {
            text: "Dashboard",
            active: true,
          },
        ],
        resource: "Admin",
      },
    },
    {
      path: "/partners",
      name: "partners",
      component: () => import("@/views/Partners.vue"),
      meta: {
        pageTitle: "Partners",
        breadcrumb: [
          {
            text: "Partners",
            active: true,
          },
        ],
        resource: "Admin",
      },
    },
    {
      path: "/transactions/:partnerId",
      name: "transactions",
      component: () => import("@/views/Transactions.vue"),
      meta: {
        pageTitle: "Transactions",
        breadcrumb: [
          {
            text: "Partner",
            to: "/partners",
            active: false,
          },
          {
            text: "Transactions",
            active: true,
          },
        ],
        resource: "Admin",
      },
    },
    {
      path: "/routes/:partnerId",
      name: "routes",
      component: () => import("@/views/Routes.vue"),
      meta: {
        pageTitle: "Routes",
        breadcrumb: [
          {
            text: "Partner",
            to: "/partners",
            active: false,
          },
          {
            text: "Routes",
            active: true,
          },
        ],
        resource: "Admin",
      },
    },
    {
      path: "/profile/:partnerId",
      name: "profile",
      component: () => import("@/views/Profile.vue"),
      meta: {
        pageTitle: "Profile",
        breadcrumb: [
          {
            text: "Partner",
            to: "/partners",
            active: false,
          },
          {
            text: "Profile",
            active: true,
          },
        ],
        resource: "Admin",
      },
    },
    {
      path: "/balances/:partnerId",
      name: "balances",
      component: () => import("@/views/Balances.vue"),
      meta: {
        pageTitle: "Balances",
        breadcrumb: [
          {
            text: "Partner",
            to: "/partners",
            active: false,
          },
          {
            text: "Balances",
            active: true,
          },
        ],
        resource: "Admin",
      },
    },
    {
      path: "/settlements",
      name: "settlements",
      component: () => import("@/views/settlement/Settlements.vue"),
      meta: {
        pageTitle: "Settlements",
        breadcrumb: [
          {
            text: "Settlements",
            active: false,
          },
        ],
        resource: "Admin",
      },
    },
    {
      path: "/settlements/create",
      name: "createSettlement",
      component: () => import("@/views/settlement/Create.vue"),
      meta: {
        pageTitle: "Create Settlement",
        breadcrumb: [
          {
            text: "Settlements",
            to: "/settlements",
            active: false,
          },
          {
            text: "Create",
            active: true,
          },
        ],
        resource: "Admin",
      },
    },
    {
      path: "/settlements/:settlementId",
      name: "settlement",
      component: () => import("@/views/settlement/Settlement.vue"),
      meta: {
        pageTitle: "Settlement",
        breadcrumb: [
          {
            text: "Settlements",
            to: "/settlements",
            active: false,
          },
          {
            text: "Settlement",
            active: true,
          },
        ],
        resource: "Admin",
      },
    },
    {
      path: "/psp",
      name: "psp",
      component: () => import("@/views/psp/Psp.vue"),
      meta: {
        pageTitle: "PSP",
        breadcrumb: [
          {
            text: "PSP",
            active: true,
          },
        ],
        resource: "Admin",
      },
    },
    {
      path: "/psp/create",
      name: "createPsp",
      component: () => import("@/views/psp/Create.vue"),
      meta: {
        pageTitle: "Create PSP",
        breadcrumb: [
          {
            text: "PSP",
            to: "/psp",
            active: false,
          },
          {
            text: "Create",
            active: true,
          },
        ],
        resource: "Admin",
      },
    },
    {
      path: "/onboarding",
      name: "onboarding",
      component: () => import("@/views/Onboarding.vue"),
      meta: {
        pageTitle: "Onboarding",
        breadcrumb: [
          {
            text: "Onboarding",
            active: true,
          },
        ],
        resource: "Admin",
      },
    },
    {
      path: "/chip",
      name: "chip",
      component: () => import("@/views/Chip.vue"),
      meta: {
        pageTitle: "Chip",
        breadcrumb: [
          {
            text: "Chip",
            active: true,
          },
        ],
        resource: "Admin",
      },
    },
    {
      path: "/bankCodes",
      name: "bankCodes",
      component: () => import("@/views/BankCodes.vue"),
      meta: {
        pageTitle: "Bank Codes",
        breadcrumb: [
          {
            text: "Bank Codes",
            active: true,
          },
        ],
        resource: "Admin",
      },
    },
    {
      path: "/login",
      name: "login",
      component: () => import("@/views/auth/Login.vue"),
      meta: {
        layout: "full",
        resource: "Auth",
        redirectIfLoggedIn: true,
      },
    },
    {
      path: "/logout",
      name: "logout",
      component: () => import("@/views/auth/Logout.vue"),
      meta: {
        layout: "full",
        resource: "Auth",
      },
    },
    {
      path: "/error-404",
      name: "error-404",
      component: () => import("@/views/error/Error404.vue"),
      meta: {
        layout: "full",
        resource: "Auth",
      },
    },
    {
      path: "/error-401",
      name: "error-401",
      component: () => import("@/views/error/Error401.vue"),
      meta: {
        layout: "full",
        resource: "Auth",
      },
    },
    {
      path: "*",
      redirect: "error-404",
    },
  ],
});

router.beforeEach(async (to, _, next) => {
  try {
    const ability = router.app.$ability;
    const isLoggedIn = await isUserLoggedIn(ability);
    if (!canNavigate(to)) {
      // Redirect to login if not logged in
      if (!isLoggedIn) return next({ name: "login" });

      // If logged in => not authorized
      return next({ name: "error-401" });
    }

    // Redirect if logged in
    if (to.meta.redirectIfLoggedIn && isLoggedIn) {
      next({ path: "/" });
    }
    return next();
  } catch (error) {
    return next({ name: "error-404" });
  }
});

router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById("loading-bg");
  if (appLoading) {
    appLoading.style.display = "none";
  }
});

export default router;
