var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('b-card', {
    attrs: {
      "title": "April's Top Performer",
      "sub-title": "This month's highest value"
    }
  }, [_c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('img', {
    staticClass: "img-fluid",
    attrs: {
      "src": _vm.topPerformer.image,
      "width": "200",
      "height": "auto",
      "alt": "Card image cap"
    }
  }), _c('div', {
    staticClass: "ml-3"
  }, [_c('h1', {
    staticClass: "mb-0"
  }, [_vm._v(_vm._s(_vm.topPerformer.name))])]), _c('div', {
    staticClass: "ml-auto"
  }, [_c('h1', {
    staticClass: "mb-0"
  }, [_vm._v(_vm._s(_vm.topPerformer.total) + " USD")])])])]), _vm._l(_vm.top3, function (partner) {
    return _c('b-card', {
      key: partner.id,
      attrs: {
        "sub-title": "Current Statistics"
      }
    }, [_c('div', {
      staticClass: "d-flex align-items-center"
    }, [_c('img', {
      staticClass: "img-fluid",
      attrs: {
        "src": partner.image,
        "width": "200",
        "height": "auto",
        "alt": "Card image cap"
      }
    }), _c('div', {
      staticClass: "ml-3"
    }, [_c('h2', {
      staticClass: "mb-0"
    }, [_vm._v(_vm._s(partner.name))])])]), _c('b-row', [_c('b-col', {
      attrs: {
        "mt-4": ""
      }
    }, [_c('b-card', {
      attrs: {
        "title": "Fund Balance"
      }
    }, [_c('b-card-text', [_c('h2', {
      staticClass: "mb-0"
    }, [_vm._v(_vm._s(partner.fundBalance))])])], 1)], 1), _c('b-col', [_c('b-card', {
      attrs: {
        "title": "Transactions"
      }
    }, [_c('b-card-text', [_c('h2', {
      staticClass: "mb-0"
    }, [_vm._v(_vm._s(partner.transactions))])])], 1)], 1), _c('b-col', [_c('b-card', {
      attrs: {
        "title": "Total Settlements"
      }
    }, [_c('b-card-text', [_c('h2', {
      staticClass: "mb-0"
    }, [_vm._v(_vm._s(partner.totalSettlements))])])], 1)], 1)], 1)], 1);
  }), _c('b-card', {
    attrs: {
      "title": "Total Merchant Earning",
      "sub-title": "Payversa total profit"
    }
  }, [_c('b-card-body', [_c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('div', {
    staticClass: "ml-auto"
  }, [_c('h1', {
    staticClass: "mb-0"
  }, [_vm._v(_vm._s(_vm.totalMerchantEarnings))])])])])], 1)], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }