<template>
    <div>
        <b-row>
            <b-col>
                <b-card>
                    <b-card-header>
                        <b-card-title>Bank Codes</b-card-title>
                        <b-button variant="primary" class="float-right" v-b-modal.add-bank-code-modal>Add Bank Code</b-button>
                    </b-card-header>
                    <b-card-body>
                        <div>
                            <vue-good-table :columns="[
                                {
                                    label: 'ID',
                                    field: 'id',
                                    type: 'number'
                                },
                                {
                                    label: 'Currency',
                                    field: 'currency',
                                    type: 'string'
                                },
                                {
                                    label: 'Country',
                                    field: 'country',
                                    type: 'string'
                                },
                                {
                                    label: 'Provider',
                                    field: 'provider',
                                    type: 'string'
                                },
                                {
                                    label: 'Bank Code',
                                    field: 'bankCode',
                                    type: 'string'
                                },
                                {
                                    label: 'Bank Name',
                                    field: 'bankName',
                                    type: 'string'
                                },
                                {
                                    label: 'PSP Code',
                                    field: 'pspCode',
                                    type: 'string'
                                },
                                {
                                    label: 'Is Active',
                                    field: 'isActive',
                                    type: 'string'
                                }
                            ]" :rows="bankCodes" :total-rows="bankCodes.length" :per-page="10" :pagination-options="{
    enabled: true,
}" :sort-options="{
    enabled: false
}"></vue-good-table>
                        </div>
                    </b-card-body>
                </b-card>
            </b-col>
        </b-row>
        <b-modal id="add-bank-code-modal" title="Add Bank Code" @ok="addBankCode">
            <b-form>
                <b-form-group label="Currency">
                    <b-form-select v-model="bankCodeForm.currency">
                        <b-form-select-option value="MYR">MYR</b-form-select-option>
                        <b-form-select-option value="SGD">SGD</b-form-select-option>
                        <b-form-select-option value="THB">THB</b-form-select-option>
                        <b-form-select-option value="PHP">PHP</b-form-select-option>
                        <b-form-select-option value="IDR">IDR</b-form-select-option>
                        <b-form-select-option value="VND">VND</b-form-select-option>
                        <b-form-select-option value="USD">USD</b-form-select-option>
                    </b-form-select>
                </b-form-group>
                <b-form-group label="Country">
                    <b-form-select v-model="bankCodeForm.country">
                        <b-form-select-option value="MY">MY</b-form-select-option>
                        <b-form-select-option value="SG">SG</b-form-select-option>
                        <b-form-select-option value="TH">TH</b-form-select-option>
                        <b-form-select-option value="PH">PH</b-form-select-option>
                        <b-form-select-option value="ID">ID</b-form-select-option>
                        <b-form-select-option value="VN">VN</b-form-select-option>
                        <b-form-select-option value="US">US</b-form-select-option>
                    </b-form-select>
                </b-form-group>
                <b-form-group label="Provider">
                    <b-form-select v-model="bankCodeForm.provider">
                        <b-form-select-option value="REDIPAY">REDIPAY</b-form-select-option>
                        <b-form-select-option value="SENANGPAY">SENANGPAY</b-form-select-option>
                        <b-form-select-option value="WALAOPAY">WALAOPAY</b-form-select-option>
                        <b-form-select-option value="ECHELONPAY">ECHELONPAY</b-form-select-option>
                        <b-form-select-option value="ECHELONPAY_SANDBOX">ECHELONPAY_SANDBOX</b-form-select-option>
                        <b-form-select-option value="CHIP">CHIP</b-form-select-option>
                        <b-form-select-option value="CHIP_SANDBOX">CHIP_SANDBOX</b-form-select-option>
                    </b-form-select>
                </b-form-group>
                <b-form-group label="Bank Code">
                    <b-form-input type="text" v-model="bankCodeForm.bankCode" />
                </b-form-group>
                <b-form-group label="Bank Name">
                    <b-form-input type="text" v-model="bankCodeForm.bankName" />
                </b-form-group>
                <b-form-group label="PSP Code">
                    <b-form-input type="text" v-model="bankCodeForm.pspCode" />
                </b-form-group>
            </b-form>
        </b-modal>
    </div>
</template>

<script>
import { getBankCodes, addBankCode } from "@/services/AdminService";
import { BButton, BCard, BCardBody, BCardHeader, BCardFooter, BCardText, BCardTitle, BCol, BFormSelect, BFormSelectOption, BModal, BPagination, BRow, BForm, BFormGroup, BFormInput } from 'bootstrap-vue';
import { VueGoodTable } from 'vue-good-table';

export default {
    name: "BankCodes",
    components: {
        BCard,
        BCardText,
        BRow,
        BForm,
        BCol,
        BCardBody,
        BCardTitle,
        BButton,
        BFormGroup,
        BFormInput,
        BFormSelectOption,
        BCardFooter,
        BCardHeader,
        BModal,
        VueGoodTable,
        BPagination,
        BFormSelect,
    },
    data() {
        return {
            bankCodes: [],
            bankCodeForm: {
                currency: null,
                country: null,
                provider: null,
                bankCode: null,
                bankName: null,
                pspCode: null,
            },
        }
    },
    async mounted() {
        this.bankCodes = await getBankCodes();
    },
    computed: {
        isMobile() {
            const currentBreakPoint = this.$store.getters["app/currentBreakPoint"];
            const isMobile = currentBreakPoint === "xs";
            return isMobile;
        },
    },
    methods: {
        async addBankCode() {
            await addBankCode(this.bankCodeForm);
            this.bankCodes = await getBankCodes();
        },
    }
}
</script>