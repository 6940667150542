import useJwt from "@/auth/jwt/useJwt";
const axios = useJwt.axiosIns;

export async function getChipPsps() {
  const res = await axios.get(`${process.env.VUE_APP_API_URL}/chip/`);
  return res.data;
}

export async function getChipDashboard(pspId) {
  const res = await axios.get(`${process.env.VUE_APP_API_URL}/chip/dashboard/${pspId}`);
  return res.data;
}

export async function addSendLimit(pspId, sendLimit) {
  const res = await axios.post(`${process.env.VUE_APP_API_URL}/chip/addsendLimit/${pspId}`, sendLimit);
  return res.data;
}

export async function addBankAccount(pspId, bankAccount) {
  const res = await axios.post(`${process.env.VUE_APP_API_URL}/chip/addBankAccount/${pspId}`, bankAccount);
  return res.data;
}
