<template>
    <div>
        <b-card v-for="partner in partners" v-bind:key="partner.id">
            <template v-slot:header>
                <div class="d-flex align-items-center">
                    <img :src="partner.image" class="img-fluid" width="50" height="auto" alt="Card image cap" />
                    <div class="ml-3">
                        <h3 class="mb-0">{{ partner.name }}</h3>
                    </div>
                </div>
            </template>

            <b-card-body>
                <!-- Fund Balance, Total Fund In, Gateway Fee, Total Merchant Fees, Total Settlements cards side by side-->
                <b-row>
                    <b-col>
                        <b-card title="Fund Balance" sub-title="Total Balance in USD">
                            <b-card-text>
                                <h2 class="mb-0">{{ partner.fundBalance }} USD</h2>
                            </b-card-text>
                        </b-card>
                    </b-col>
                    <b-col>
                        <b-card title="Total Fund In" sub-title="Total Success Fund-in in USD">
                            <b-card-text>
                                <h2 class="mb-0">{{ partner.fundInBalance }} USD</h2>
                            </b-card-text>
                        </b-card>
                    </b-col>
                    <b-col>
                        <b-card title="Gateway Fee" sub-title="Total Gateway Fees in USD">
                            <b-card-text>
                                <h2 class="mb-0">{{ partner.gatewayFee }} USD</h2>
                            </b-card-text>
                        </b-card>
                    </b-col>
                    <b-col>
                        <b-card title="Total Merchant Fees" sub-title="Total Merchant Fees in USD">
                            <b-card-text>
                                <h2 class="mb-0">{{ partner.totalMerchantFee }} USD</h2>
                            </b-card-text>
                        </b-card>
                    </b-col>
                    <b-col>
                        <b-card title="Total Settlements" sub-title="Total Settlements in USD">
                            <b-card-text>
                                <h2 class="mb-0">{{ partner.totalSettlement }} USD</h2>
                            </b-card-text>
                        </b-card>
                    </b-col>
                </b-row>

                <!-- Transactions, Routing & Config, Profile, Balances buttons -->
                <b-row class="d-flex align-items-center">
                    <b-button class="mr-1" variant="primary" size="lg" @click="transactionBtnHandler(partner.id)">Transactions</b-button>
                    <b-button class="mr-1" variant="primary" size="lg" @click="routingBtnHandler(partner.id)">Routing & Config</b-button>
                    <b-button class="mr-1" variant="primary" size="lg" @click="profileBtnHandler(partner.id)">Profile</b-button>
                    <b-button variant="primary" size="lg" @click="balanceBtnHandler(partner.id)">Balances</b-button>
                </b-row>
            </b-card-body>
        </b-card>
    </div>
</template>

<script>
import { BCard, BCardText, BCol, BRow, BCardBody, BButton } from 'bootstrap-vue';
import { getPartners } from "@/services/AdminService";

export default {
    name: 'Partners',
    components: {
        BCard,
        BCardText,
        BRow, BCol, BCardBody, BButton
    },
    data() {
        return {
            partners: []
        }
    },
    async mounted() {
        this.partners = await getPartners();
    },
    methods: {
        transactionBtnHandler(partnerId) {
            this.$router.push({ path: `/transactions/${partnerId}` });
        },
        routingBtnHandler(partnerId) {
            this.$router.push({ path: `/routes/${partnerId}` });
        },
        profileBtnHandler(partnerId) {
            this.$router.push({ path: `profile/${partnerId}` });
        },
        balanceBtnHandler(partnerId) {
            this.$router.push({ path: `/balances/${partnerId}` });
        }
    }
}
</script>

<style></style>
