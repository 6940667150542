var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('b-row', [_c('b-col', [_c('b-card', {
    staticClass: "text-center",
    attrs: {
      "header-bg-variant": "primary",
      "header-text-variant": "white",
      "header": "".concat(_vm.settlement.account.name, " Fund Balance")
    }
  }, [_c('b-card-text', {
    staticClass: "mt-4"
  }, [_c('h2', [_vm._v(_vm._s(_vm.settlement.account.balance) + " " + _vm._s(_vm.settlement.account.currency))]), _c('h2', [_vm._v(_vm._s(_vm.settlement.account.usdBalance) + " USD")])])], 1)], 1)], 1), _c('b-row', [_c('b-col', [_c('b-card', {
    attrs: {
      "title": "Process Settlement",
      "sub-title": "Process settlement to wallet or bank account"
    }
  }, [_c('b-card-body', [_c('b-row', [_c('b-col', [_c('b-form-group', {
    attrs: {
      "label": "Amount (".concat(_vm.settlement.account.currency, ")"),
      "label-for": "amount"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "amount",
      "size": "lg",
      "type": "number",
      "readonly": ""
    },
    model: {
      value: _vm.settlement.amount,
      callback: function callback($$v) {
        _vm.$set(_vm.settlement, "amount", $$v);
      },
      expression: "settlement.amount"
    }
  })], 1)], 1), _c('b-col', [_c('b-form-group', {
    attrs: {
      "label": "Account",
      "label-for": "account"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "account",
      "size": "lg",
      "readonly": ""
    },
    model: {
      value: _vm.settlement.account.name,
      callback: function callback($$v) {
        _vm.$set(_vm.settlement.account, "name", $$v);
      },
      expression: "settlement.account.name"
    }
  })], 1)], 1)], 1), _c('b-row', [_c('b-col', [_c('b-form-group', {
    attrs: {
      "label": "Destination",
      "label-for": "destination"
    }
  }, [_c('b-form-input', {
    attrs: {
      "value": "Wallet - USDT",
      "size": "lg",
      "readonly": ""
    }
  })], 1)], 1), _c('b-col', [_c('b-form-group', {
    attrs: {
      "label": "Wallet Network"
    }
  }, [_c('b-form-input', {
    attrs: {
      "size": "lg",
      "readonly": ""
    },
    model: {
      value: _vm.settlement.walletNetwork,
      callback: function callback($$v) {
        _vm.$set(_vm.settlement, "walletNetwork", $$v);
      },
      expression: "settlement.walletNetwork"
    }
  })], 1)], 1), _c('b-col', [_c('b-form-group', {
    attrs: {
      "label": "Address",
      "label-for": "address"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "address",
      "size": "lg",
      "readonly": ""
    },
    model: {
      value: _vm.settlement.walletAddress,
      callback: function callback($$v) {
        _vm.$set(_vm.settlement, "walletAddress", $$v);
      },
      expression: "settlement.walletAddress"
    }
  })], 1)], 1)], 1), _c('b-row', [_c('b-col', [_c('b-form-group', {
    attrs: {
      "label": "Settlement Receipt"
    }
  }, [_vm.settlement.attachment === null ? _c('b-form-file', {
    attrs: {
      "size": "lg",
      "accept": "application/pdf, image/png, image/jpeg",
      "placeholder": "Choose a file or drop it here..."
    },
    model: {
      value: _vm.settlement.attachment,
      callback: function callback($$v) {
        _vm.$set(_vm.settlement, "attachment", $$v);
      },
      expression: "settlement.attachment"
    }
  }) : _c('a', {
    attrs: {
      "href": _vm.settlement.attachment,
      "target": "_blank"
    }
  }, [_c('b-button', {
    attrs: {
      "variant": "primary",
      "size": "sm"
    }
  }, [_vm._v("View Receipt")])], 1)], 1)], 1), _c('b-col', [_c('b-form-group', {
    attrs: {
      "label": "Status",
      "label-for": "status"
    }
  }, [_c('b-form-select', {
    attrs: {
      "id": "status",
      "size": "lg",
      "options": _vm.status
    },
    model: {
      value: _vm.settlement.status,
      callback: function callback($$v) {
        _vm.$set(_vm.settlement, "status", $$v);
      },
      expression: "settlement.status"
    }
  })], 1)], 1), _c('b-col', [_c('b-form-group', {
    attrs: {
      "label": "Rate (".concat(_vm.settlement.account.currency, " to USD)"),
      "label-for": "rate"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "rate",
      "size": "lg"
    },
    model: {
      value: _vm.settlement.rate,
      callback: function callback($$v) {
        _vm.$set(_vm.settlement, "rate", $$v);
      },
      expression: "settlement.rate"
    }
  })], 1)], 1)], 1), _c('b-button', {
    staticClass: "mt-4",
    attrs: {
      "variant": "primary",
      "size": "lg",
      "block": ""
    },
    on: {
      "click": _vm.onSubmitBtnHandler
    }
  }, [_vm._v("Done")])], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }