import useJwt from "@/auth/jwt/useJwt";
const axios = useJwt.axiosIns;

export async function getDashboard() {
  const res = await axios.get(`${process.env.VUE_APP_API_URL}/admin/dashboard`);
  return res.data;
}

export async function getPartners() {
  const res = await axios.get(`${process.env.VUE_APP_API_URL}/partner`);
  return res.data.partners;
}

export async function getTransactions(accountId, skip, take, sort, filter) {
  const res = await axios.get(`${process.env.VUE_APP_API_URL}/transaction/account/${accountId}?skip=${skip}&take=${take}&sort=${JSON.stringify(sort)}&filter=${JSON.stringify(filter)}`);
  return res.data;
}

export async function getAccounts(partnerId) {
  const res = await axios.get(`${process.env.VUE_APP_API_URL}/partner/accounts/${partnerId}`);
  return res.data.accounts;
}

export async function getPspConfigs(partnerId) {
  const res = await axios.get(`${process.env.VUE_APP_API_URL}/psp/config/${partnerId}`);
  return res.data;
}

export async function updatePspConfig(partnerId, configs, enabledPsp) {
  const res = await axios.put(`${process.env.VUE_APP_API_URL}/psp/config/${partnerId}`, { configs, enabledPsp });
  return res.data;
}

export async function getProfile(partnerId) {
  const res = await axios.get(`${process.env.VUE_APP_API_URL}/admin/partner/${partnerId}`);
  return res.data.partner;
}

export async function addAccount(partnerId, currency) {
  await axios.post(`${process.env.VUE_APP_API_URL}/account`, {
    partnerId: partnerId,
    currencyCode: currency,
    rate: 1,
  });
}

export async function updateAccounts(accounts) {
  const accounts2 = accounts.map((account) => {
    return {
      id: account.id,
      rate: parseFloat(account.rate),
    };
  });

  await axios.put(`${process.env.VUE_APP_API_URL}/account`, { accounts: accounts2 });
}

export async function regenerate(partnerId) {
  const res = await axios.post(`${process.env.VUE_APP_API_URL}/partner/regenerate`, { partnerId });
  return res.data;
}

export async function updateProfile(partnerId, profile) {
  await axios.put(`${process.env.VUE_APP_API_URL}/admin/partner/${partnerId}`, { partner: profile });
}

export async function getBalances(partnerId) {
  const res = await axios.get(`${process.env.VUE_APP_API_URL}/partner/balance/${partnerId}`);
  return res.data;
}

export async function getSettlements() {
  const res = await axios.get(`${process.env.VUE_APP_API_URL}/settlement`);
  return res.data;
}

export async function getSettlement(settlementId) {
  const res = await axios.get(`${process.env.VUE_APP_API_URL}/settlement/${settlementId}`);
  return res.data;
}

export async function updateSettlement(data) {
  const formData = new FormData();
  formData.append("file", data.attachment);
  formData.append("id", data.id);
  formData.append("status", data.status);
  formData.append("rate", data.rate);

  await axios.put(`${process.env.VUE_APP_API_URL}/settlement`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}

export async function getPsps() {
  const res = await axios.get(`${process.env.VUE_APP_API_URL}/psp`);
  return res.data;
}

export async function updatePsps(psps) {
  await axios.put(`${process.env.VUE_APP_API_URL}/psp`, { psps });
}

export async function createPsp(psp) {
  await axios.post(`${process.env.VUE_APP_API_URL}/psp`, { psp });
}

export async function createPartner(partner) {
  const formData = new FormData();
  formData.append("image", partner.image);
  formData.append("name", partner.name);
  formData.append("address", partner.address);
  formData.append("companyRegNo", partner.companyRegNo);
  formData.append("email", partner.email);
  formData.append("password", partner.password);
  formData.append("partnerFee", partner.partnerFee);
  formData.append("callbackURL", partner.callbackURL);
  formData.append("redirectURL", partner.redirectURL);
  formData.append("currency", partner.currency);

  await axios.post(`${process.env.VUE_APP_API_URL}/admin/onboarding`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}

export async function createSettlement(form) {
  await axios.post(
    `${process.env.VUE_APP_API_URL}/settlement/adminCreate`,
    {
      partnerId: form.partner,
      accountId: form.account,
      amount: form.amount,
      destinationAccount: form.account,
      walletNetwork: form.network,
      walletAddress: form.address,
      status: form.status,
      file: form.receipt,
    },
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
}

export async function getBankCodes() {
  const res = await axios.get(`${process.env.VUE_APP_API_URL}/bankCode`);
  return res.data;
}

export async function addBankCode(bankCode) {
  await axios.post(`${process.env.VUE_APP_API_URL}/bankCode`, bankCode);
}
