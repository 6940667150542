<template>
  <p class="clearfix mb-0">
    <span class="float-md-right d-none d-md-block">Payversa Dashboard v1.0
      <font-awesome-icon icon="heart-broken" size="4x" class="icons" />
    </span>
  </p>
</template>

<script>

export default {
}
</script>
