<template>
    <div>
        <b-row>
            <b-col>
                <b-card>
                    <b-card-header>
                        <b-button variant="primary" size="lg" @click="$router.push('/settlements/create')">Create Settlement</b-button>
                    </b-card-header>
                    <b-card-body>
                        <vue-good-table :columns="header" :rows="columnData" @on-cell-click="cellClickHandler" :total-rows="pageTotal" :per-page="pageLength" :pagination-options="{
                            enabled: true,
                        }" :sort-options="{
    enabled: true
}">
                            <template slot="table-row" slot-scope="props">
                                <span v-if="props.column.field === 'settlementId'">
                                    <a href="javascript:void(0)">
                                        {{ props.row.settlementId }}
                                    </A>
                                </span>
                                <span v-else-if="props.column.field === 'attachment'">
                                    <a href="javascript:void(0)">
                                        screenshot.png
                                    </a>
                                </span>
                                <span v-else-if="props.column.field === 'status'">
                                    <b-badge variant="success" v-if="props.row[props.column.field] === 'SUCCESS'">
                                        {{ props.row[props.column.field] }}
                                    </b-badge>
                                    <b-badge variant="warning" v-else-if="props.row[props.column.field] === 'PENDING'">
                                        {{ props.row[props.column.field] }}
                                    </b-badge>
                                    <b-badge variant="danger" v-else>
                                        {{ props.row[props.column.field] }}
                                    </b-badge>
                                </span>
                                <span v-else>
                                    {{ props.row[props.column.field] }}
                                </span>

                            </template>
                            <template slot="pagination-bottom" slot-scope="props">
                                <div class="d-flex justify-content-between flex-wrap">
                                    <div class="d-flex align-items-center mb-0 mt-1">
                                        <span class="text-nowrap">
                                            Showing 1 to
                                        </span>
                                        <b-form-select v-model="pageLength" :options="['5', '10', '20', '50', '100']" class="mx-1" @input="(value) => props.perPageChanged({ currentPerPage: value })" />
                                        <span class="text-nowrap"> of {{ props.total }} entries </span>
                                    </div>

                                    <b-pagination :value="1" :total-rows="pageTotal" :per-page="pageLength" first-number last-number align="right" prev-class="prev-item" next-class="next-item" class="mt-1 mb-0" @input="(value) => props.pageChanged({ currentPage: value })">
                                        <template #prev-text>
                                            <feather-icon icon="ChevronLeftIcon" size="18" />
                                        </template>
                                        <template #next-text>
                                            <feather-icon icon="ChevronRightIcon" size="18" />
                                        </template>
                                    </b-pagination>
                                </div>
                            </template>
                        </vue-good-table>
                    </b-card-body>
                </b-card>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import { getSettlements } from "@/services/AdminService";
import { BButton, BCard, BCardBody, BCardHeader, BCardText, BCol, BFormCheckbox, BFormGroup, BFormInput, BFormSelect, BPagination, BRow, BBadge } from 'bootstrap-vue';
import { VueGoodTable } from 'vue-good-table';

export default {
    name: "Settlements",
    components: {
        BCard,
        BCardText, BBadge,
        BRow, BCol, BCardBody, BButton, BFormCheckbox, BFormInput, BFormSelect, BFormGroup, BButton, BPagination, BCardHeader,
        VueGoodTable,
    },
    data() {
        return {
            pageTotal: 0,
            pageLength: 10,
            columnData: [],
        }
    },
    computed: {
        isMobile() {
            const currentBreakPoint = this.$store.getters["app/currentBreakPoint"];
            const isMobile = currentBreakPoint === "xs";
            return isMobile;
        },
        header() {
            return [
                {
                    label: "Settlement ID",
                    field: "settlementId",
                },
                {
                    label: "Amount (USD)",
                    field: "amount",
                },
                {
                    label: "Destination",
                    field: "destinationAccount",
                },
                {
                    label: "Network",
                    field: "walletNetwork",
                },
                {
                    label: "Account",
                    field: "walletAddress",
                },
                {
                    label: "Status",
                    field: "status",
                },
                {
                    label: "Attachment",
                    field: "attachment",
                },
                {
                    label: "Date Submitted",
                    field: "createdAt",
                    type: "date",
                    dateInputFormat: "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'",
                    dateOutputFormat: "dd/MM/yyyy HH:mm:ss",
                },
                {
                    label: "Date Processed",
                    field: "approvedAt",
                    type: "date",
                    dateInputFormat: "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'",
                    dateOutputFormat: "dd/MM/yyyy HH:mm:ss",
                },
            ]
        }
    },
    async mounted() {
        this.columnData = await getSettlements();
        this.pageTotal = this.columnData.length;
    },
    methods: {
        cellClickHandler(e) {
            switch (e.column.field) {
                case "settlementId":
                    this.$router.push({ path: `/settlements/${e.row.id}` });
                    break;
                case "attachment":
                    window.open(e.row.attachment, '_blank');
                    break;
                default:
                    break;
            }
        },
    }
}

</script>